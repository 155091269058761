import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import React, { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from "react"
import { SelectInput } from "../../../../components/inputs/select-input/SelectInput"
import { RseeProjectContext } from "../../../../core/context/beem-pilot/rsee/rsee-project-context"
import { RseeProject } from "../../../../core/dto/rsee/rsee-project"
import { ProjectStatusEnum, projectStatusToLabel } from "../../../../core/enum/projectStatusEnum"
import { useRsee } from "../../../../core/hooks/rsee/use-rsee"
import { enumToArray } from "../../../../core/services/helper-service"
import { RseeProjectUpdate } from "../../../../core/dto/rsee/rsee-project-update"

const selectOptions = enumToArray(ProjectStatusEnum)
  .filter(
    (status) =>
      status === ProjectStatusEnum.IN_PROGRESS ||
      status === ProjectStatusEnum.FINISHED ||
      status === ProjectStatusEnum.ARCHIVED
  )
  .map((enumm: ProjectStatusEnum) => ({
    value: enumm,
    label: projectStatusToLabel(enumm),
  }))

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

function formFromProject(rseeProject: RseeProject | undefined): RseeProjectUpdate {
  return rseeProject?.id
    ? {
        projectName: rseeProject.projectName,
        projectStatus: rseeProject.projectStatus,
        businessCode: rseeProject.businessCode,
      }
    : { projectName: "", projectStatus: ProjectStatusEnum.IN_PROGRESS, businessCode: "" }
}

export function RseeProjectFormDialog({ open, setOpen }: IProps): React.JSX.Element {
  const { rseeProject, setRseeProject } = useContext(RseeProjectContext)
  const { updateRseeProject } = useRsee()

  const [rseeProjectForm, setRseeProjectForm] = useState<RseeProjectUpdate>(formFromProject(rseeProject))
  const [error, setError] = useState<Record<string, any>>({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setRseeProjectForm(formFromProject(rseeProject))
  }, [formFromProject, rseeProject?.id])

  function isValid(): boolean {
    let valid = true
    if (!rseeProjectForm?.projectName || rseeProjectForm.projectName === "") {
      setError({ ...error, nom: "Le nom est obligatoire" })
      valid = false
    } else if (rseeProjectForm?.projectName.length > 500) {
      setError({ ...error, nom: "Le nom est trop long" })
      valid = false
    } else {
      rseeProjectForm.projectName = rseeProjectForm.projectName.trim()
    }

    return valid
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.value

    setRseeProjectForm({
      ...rseeProjectForm,
      [target.id]: value,
    })
  }

  function handleChangeStatus(selectedStatus: ProjectStatusEnum): void {
    setRseeProjectForm({
      ...rseeProjectForm,
      projectStatus: selectedStatus,
    })
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    setError({})

    if (!isSubmitting && isValid() && rseeProject?.id) {
      setIsSubmitting(true)
      updateRseeProject(rseeProject.id, rseeProjectForm)
        .then((newRseeProject) => {
          setRseeProject(newRseeProject)
          handleClose()
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  function handleClose(): void {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Mise à jour du projet</DialogTitle>
      <DialogContent>
        {rseeProject?.id && (
          <Grid container rowSpacing={2} component="form" id="rsee-project-form" onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <TextField
                id="nom"
                value={rseeProjectForm?.projectName}
                label="RSEE"
                onChange={handleChange}
                fullWidth
                error={!!error.nom}
                helperText={error.nom}
                inputProps={{ maxLength: 500 }}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                id="status"
                mode="direct"
                handleChange={handleChangeStatus}
                label="Statut du projet"
                options={selectOptions}
                selectedOption={rseeProjectForm.projectStatus}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Annuler
        </Button>
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button variant="contained" type="submit" form="rsee-project-form">
            Valider
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
