import { CircularProgress, Typography } from "@mui/material"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { TableauDashboard } from "../../../../components/TableauDashboard/TableauDashboard"
import { pagesUrl } from "../../../../core/appConstants"
import { AppNavBarContext, NavbarTypeEnum } from "../../../../core/context/nav-bar/AppNavBarContext"
import { DocumentSharedInfo } from "../../../../core/dto/rsee/documentSharedInfo"
import { ShareLink } from "../../../../core/dto/rsee/share-link"
import { useQuery } from "../../../../core/hooks/miscellaneous/use-query"
import { useProjects } from "../../../../core/hooks/projects/use-projects"
import { useRsee } from "../../../../core/hooks/rsee/use-rsee"
import { TableauViz } from "../../../../core/services/tableau/tableau-service"

export function ExternalDocumentDashboard(): React.JSX.Element {
  const query = useQuery()

  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)

  const { fetchShareLinkInformation } = useProjects()
  const { fetchRseeDocument, fetchDashboardUrl } = useRsee()
  const [dashboardInfo, setDashboardInfo] = useState<ShareLink>()
  const [isLoading, setIsLoading] = useState(true)
  const [dashboardUrl, setDashboardUrl] = useState<string | undefined>()

  const dashboardToken: string = useMemo(() => query.get("token") ?? "", [query])

  const viz = useRef<any>(new TableauViz())

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.UNKNOWN)
    setPreviousUrl(pagesUrl.BEEM_PILOT_HOME)
    setStepHeader(0)
  }, [setPreviousUrl, setStepHeader, setTypeNavBar])

  useEffect(() => {
    setIsLoading(true)
    fetchShareLinkInformation(dashboardToken)
      .then((newDashboardInformation: DocumentSharedInfo) => {
        setDashboardInfo(newDashboardInformation)
        return newDashboardInformation.dashboardUrl
      })
      .then((newDashboardUrl) => setDashboardUrl(newDashboardUrl))
      .finally(() => setIsLoading(false))
  }, [fetchShareLinkInformation, dashboardToken, fetchRseeDocument, fetchDashboardUrl])

  if (isLoading || !dashboardUrl) {
    return <CircularProgress />
  } else if (!dashboardInfo) {
    return <Typography>Impossible d'accéder à la ressource</Typography>
  } else {
    return (
      <TableauDashboard
        dashboardUrl={dashboardUrl}
        isDataReady
        tableauParameter={[{ name: "0P_Document_Id", value: [dashboardInfo.documentId] }]}
        filterToAdd={[]}
        vizRef={viz}
      />
    )
  }
}
