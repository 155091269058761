import React from "react"
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from "react-router-dom"
import { GoogleReCaptchaContextProvider } from "../components/captcha/google-recaptcha-context"
import FullScreenViewer from "../components/ifc-displayer/fullscreen-viewer"
import Layout from "../components/layout/layout"
import LayoutAdmin from "../components/layout/layoutAdmin"
import RequireAuth from "../components/redirect/require-auth"
import RequireAuthUltimate from "../components/redirect/require-auth-ultimate"
import RequireNoAuth from "../components/redirect/require-no-auth"
import RequireOrganization from "../components/redirect/require-organization"
import { adminPagesUrl, pagesUrl } from "../core/appConstants"
import RseeDocumentContextProvider from "../core/context/beem-pilot/rsee/rsee-document-context"
import { RseeProjectContextProvider } from "../core/context/beem-pilot/rsee/rsee-project-context"
import BimModelContextProvider from "../core/context/bim-model/bim-model-context"
import BimModelFileContextProvider from "../core/context/bim-model/bim-model-file-context"
import { BimModelImportContextProvider } from "../core/context/bim-model/bim-model-import-context"
import CalculationCarbonAdditionalDataProvider from "../core/context/calculation/calculation-carbon-additional-data"
import CalculationCarbonExtractedDataProvider from "../core/context/calculation/calculation-carbon-extracted-data"
import CalculationCarbonFlateRateProvider from "../core/context/calculation/calculation-carbon-flate-rate-data"
import CalculationCarbonInformationProjectContextProvider from "../core/context/calculation/calculation-carbon-information-project"
import CalculationCarbonMaterialFilterContextProvider from "../core/context/calculation/calculation-carbon-material-filter-context"
import CalculationContextProvider from "../core/context/calculation/calculation-context"
import CodeAcvCompletionContextProvider from "../core/context/code-acv/code-acv-completion-context"
import CodeCustomContextProvider from "../core/context/code-acv/code-custom-context"
import CodesCompletionsContextProvider from "../core/context/code-acv/codes-completions-context"
import CodesExtraitsListContextProvider from "../core/context/code-acv/codes-extraits-list-context"
import IniesRecordContextProvider from "../core/context/inies-context"
import { LotContextProvider } from "../core/context/lot-context"
import MaterialLibContextProvider from "../core/context/material/material-lib-context"
import MaterialLibDeclarationTypeContextProvider from "../core/context/material/material-lib-declaration-type-context"
import MaterialLibFilterContextProvider from "../core/context/material/material-lib-filter-context"
import MaterialLibOrganizationIndicatorContextProvider from "../core/context/material/material-lib-organization-indicator-context"
import MaterialLibProjectIndicatorContextProvider from "../core/context/material/material-lib-project-indicator-context"
import MaterialLibSearchContextProvider from "../core/context/material/material-lib-search-context"
import AdminOrganizationContextProvider from "../core/context/organization/admin-organization-context"
import DomainNameValidatorContextProvider from "../core/context/organization/domain-name-validator-context"
import OrganizationsListContextProvider from "../core/context/organization/organization-list-context"
import ProjectMaterialContextProvider from "../core/context/project-material-context"
import { ProjectContextProvider } from "../core/context/project/project-context"
import ProjectTypeContextProvider from "../core/context/project/project-type-context"
import SelectedPhaseProvider from "../core/context/selected-phase-context"
import TutorialEnabledContextProvider from "../core/context/tutoriel-enabled-context"
import ProjectRoleContextProvider from "../core/context/user/project-role-context"
import RseeProjectRoleContextProvider from "../core/context/user/rsee-project-role-context"
import VariantContextProvider from "../core/context/variant-context"
import { getEnvProperty, PROPERTIES } from "../core/services/environment-service"
import { adminMenu } from "../pages/admin-page/admin-menu"
import BimProjectOrganizationPage from "../pages/admin-page/organization/bim-project-organization-page"
import OrganizationList from "../pages/admin-page/organization/organization-list"
import OrganizationPage from "../pages/admin-page/organization/organization-page"
import RseeProjectOrganizationPage from "../pages/admin-page/organization/rsee-project-organization-page"
import UserTableOrganizationPage from "../pages/admin-page/organization/user-table-organization-page"
import AdminStatistics from "../pages/admin-page/statistics/admin-statistics"
import TestUsersList from "../pages/admin-page/tests-users/test-users-list"
import AdminInviteUserForm from "../pages/admin-page/user/admin-invite-user-form"
import AdminUpdateUserForm from "../pages/admin-page/user/admin-update-user-form"
import ConfirmPage from "../pages/authentication/confirm-page/confirm-page"
import ForgotPasswordPage from "../pages/authentication/forgot-password-page/forgot-password-page"
import SignInPage from "../pages/authentication/sign-in-page/sign-in-page"
import SignUpPage from "../pages/authentication/sign-up-page/sign-up-page"
import SignUpTestUserPage from "../pages/authentication/sign-up-test-user-page/sign-up-test-user-page"
import BeemGuideExcelAcceptPage from "../pages/beem-guide-excel-page/beem-guide-excel-accept-page"
import BeemGuideExcelPage from "../pages/beem-guide-excel-page/beem-guide-excel-page"
import { KairnialDashboardPage } from "../pages/kairnial/KairnialDashboardPage"
import UserInvitationPage from "../pages/my-organization-page/user-invite-page/user-invitation-page"
import UserUpdatePage from "../pages/my-organization-page/user-update-page/user-update-page"
import { NoMatch } from "../pages/no-match/no-match"
import OrganizationDashboardPage from "../pages/organization-dashboard-page/OrganizationDashboardPage"
import OrganizationMaterialLibPage from "../pages/organization-material-page/organization-material-lib-page"
import { BimModelUploadPage } from "../pages/project-page/bim-model/bim-model-upload-page/bim-model-upload-page"
import TutorialPage from "../pages/project-page/bim-model/tutorial-page/tutorial-page"
import GeneralInformationPage from "../pages/project-page/calculation"
import { AdditionalCodePage } from "../pages/project-page/calculation/additional-code-page/additional-code-page"
import { ExtractedCodePage } from "../pages/project-page/calculation/extracted-code-page/extracted-code-page"
import FlateRatesPage from "../pages/project-page/calculation/flate-rates-page/flate-rates-page"
import WorksitePage from "../pages/project-page/calculation/worksite-page"
import ProjectControlBoard from "../pages/project-page/control-board"
import ProjectPhaseStepper from "../pages/project-page/control-board/project-phase-stepper"
import DashboardsPage from "../pages/project-page/dashboard/dashboard-page"
import { ProjectHomePage } from "../pages/project-page/home-page/project-home-page"
import ProjectForm from "../pages/project-page/project-info/project-form"
import ProjectMaterialLibPage from "../pages/project-page/project-material-lib/project-material-lib-page"
import RseeDocumentDashboard from "../pages/project-page/rsee/rsee-dashboard/rsee-document-dashboard"
import RseeDocumentImportLog from "../pages/project-page/rsee/rsee-document-import-log/rsee-document-import-log"
import RseeDocumentListPage from "../pages/project-page/rsee/rsee-document-list/rsee-document-list-page"
import RseeGroupPage from "../pages/project-page/rsee/rsee-group-page/rsee-group-page"
import { RseeImportForm } from "../pages/project-page/rsee/rsee-import-form/RseeImportForm"
import { TeamPage } from "../pages/project-page/team-page/team-page"
import { LayoutIcade } from "../pages/report-icade/layout-icade"
import { ReportIcade } from "../pages/report-icade/report-icade"
import { LayoutIlotLabro } from "../pages/report-ilot-labro/layout-ilot-labro"
import { ReportIlotLabroR4 } from "../pages/report-ilot-labro/report-ilot-labro-r4"
import { ReportIlotLabroR7 } from "../pages/report-ilot-labro/report-ilot-labro-r7"
import { LayoutSequens } from "../pages/report-sequens/layout-sequens"
import { ReportSequens } from "../pages/report-sequens/report-sequens"
import { userMenu } from "../pages/user-menu"
import { BSRoutes } from "./BSRouter"
import { RseeRoutes } from "./RseeRouter"
import { TableauExtensionsRoutes } from "./TableauExtensionsRouter"
import { SideBarContextProvider } from "../core/context/side-bar/side-bar-context"
import { DepartmentContextProvider } from "../core/context/departmentContext"
import { OrganizationContextProvider } from "../core/context/organization/organization-context"
import { NavBarContextProvider } from "../core/context/nav-bar/AppNavBarContext"
import { BSLayout } from "../components/layout/BSLayout"
import { BSVariantContextProvider } from "../core/context/beem-shot/BSVariant/BSVariantContext"
import { HomePage } from "../pages/home-page/HomePage"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <ProjectTypeContextProvider>
          <Outlet />
        </ProjectTypeContextProvider>
      }>
      {/* Not authenticated user */}
      {TableauExtensionsRoutes}
      <Route path={pagesUrl.KAIRNIAL_DASHBOARD} element={<KairnialDashboardPage />} />
      <Route
        element={
          <RequireNoAuth>
            <Outlet />
          </RequireNoAuth>
        }>
        <Route element={<LayoutIcade />}>
          <Route path={pagesUrl.ICADE_REPORT} element={<ReportIcade />} />
        </Route>
        <Route element={<LayoutSequens />}>
          <Route path={pagesUrl.SEQUENS_REPORT} element={<ReportSequens />} />
        </Route>
        <Route element={<LayoutIlotLabro />}>
          <Route path={pagesUrl.ILOT_LABRO_R4_REPORT} element={<ReportIlotLabroR4 />} />
          <Route path={pagesUrl.ILOT_LABRO_R7_REPORT} element={<ReportIlotLabroR7 />} />
        </Route>
        <Route path="/" element={<SignInPage />} />
        <Route path={pagesUrl.SIGN_IN_PAGE} element={<SignInPage />} />
        <Route path={pagesUrl.SIGN_UP_INVITATION_PAGE} element={<SignUpPage />} />
        <Route
          path={pagesUrl.SIGN_UP_PAGE}
          element={
            <GoogleReCaptchaContextProvider reCaptchaKey={getEnvProperty(PROPERTIES.REACT_APP_RECAPTCHA_SITE_KEY)}>
              <DomainNameValidatorContextProvider>
                <SignUpTestUserPage />
              </DomainNameValidatorContextProvider>
            </GoogleReCaptchaContextProvider>
          }
        />
        <Route path={pagesUrl.FORGOT_PWD_PAGE} element={<ForgotPasswordPage />} />
        <Route path={pagesUrl.CONFIRM_PAGE} element={<ConfirmPage />} />
        <Route path={pagesUrl.BEEM_GUIDE_EXCEL} element={<BeemGuideExcelPage />} />
        <Route path={pagesUrl.BEEM_GUIDE_EXCEL_ACCEPT} element={<BeemGuideExcelAcceptPage />} />
      </Route>
      {/* End not authenticated user */}

      {/* User part */}
      <Route
        element={
          <RequireAuth>
            <Layout mainMenu={userMenu} />
          </RequireAuth>
        }>
        {/* End user account */}
        {/* Organization global control panel page */}
        <Route path={pagesUrl.GLOBAL_CONTROL_PANEL_DASHBOARD} element={<OrganizationDashboardPage />} />
        {/* End organization global control panel page */}
        {/* My organization */}
        <Route
          path={pagesUrl.MY_ORGANIZATION_INVITE_USER_PAGE}
          element={
            <DomainNameValidatorContextProvider>
              <UserInvitationPage />
            </DomainNameValidatorContextProvider>
          }
        />
        <Route
          path={pagesUrl.MY_ORGANIZATION_EDIT_USER_PAGE}
          element={
            <DomainNameValidatorContextProvider>
              <UserUpdatePage />
            </DomainNameValidatorContextProvider>
          }
        />
        {/* End my organization */}
        {/* Material lib */}
        <Route
          path={pagesUrl.MY_MATERIAL_PAGE}
          element={
            <MaterialLibFilterContextProvider>
              <MaterialLibSearchContextProvider>
                <MaterialLibContextProvider>
                  <IniesRecordContextProvider>
                    <MaterialLibOrganizationIndicatorContextProvider>
                      <MaterialLibProjectIndicatorContextProvider>
                        <MaterialLibDeclarationTypeContextProvider>
                          <OrganizationMaterialLibPage />
                        </MaterialLibDeclarationTypeContextProvider>
                      </MaterialLibProjectIndicatorContextProvider>
                    </MaterialLibOrganizationIndicatorContextProvider>
                  </IniesRecordContextProvider>
                </MaterialLibContextProvider>
              </MaterialLibSearchContextProvider>
            </MaterialLibFilterContextProvider>
          }
        />
        {/* End material lib */}

        {/* RSEE project */}
        <Route path={pagesUrl.RSEE_PROJECT_NEW} element={<RseeImportForm />} />
        <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT_SUMMARY_IMPORT} element={<RseeDocumentImportLog />} />
        <Route
          path={pagesUrl.RSEE_PROJECT}
          element={
            <RseeProjectContextProvider>
              <RseeProjectRoleContextProvider>
                <Outlet />
              </RseeProjectRoleContextProvider>
            </RseeProjectContextProvider>
          }>
          <Route path={pagesUrl.RSEE_PROJECT} element={<RseeDocumentListPage />} />
          <Route path={pagesUrl.RSEE_PROJECT_GROUP_UPDATE} element={<RseeGroupPage />} />
          <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT_NEW} element={<RseeImportForm />} />
          <Route
            path={pagesUrl.RSEE_PROJECT_DOCUMENT}
            element={
              <RseeDocumentContextProvider>
                <Outlet />
              </RseeDocumentContextProvider>
            }>
            <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT} element={<RseeDocumentDashboard />} />
            <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT_UPDATE} element={<RseeImportForm />} />
            <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT_GROUP_UPDATE} element={<RseeGroupPage />} />
          </Route>
        </Route>
        {/* End rsee project*/}
        <Route path={pagesUrl.PROJECTS_PAGE} element={<ProjectHomePage />} />
        <Route
          path={`${pagesUrl.PROJECT_PAGE}/:projectId`}
          element={
            <LotContextProvider>
              <ProjectContextProvider>
                <ProjectRoleContextProvider>
                  <SelectedPhaseProvider>
                    <BimModelContextProvider>
                      <BimModelFileContextProvider>
                        <TutorialEnabledContextProvider>
                          <CalculationContextProvider>
                            <VariantContextProvider>
                              <Outlet />
                            </VariantContextProvider>
                          </CalculationContextProvider>
                        </TutorialEnabledContextProvider>
                      </BimModelFileContextProvider>
                    </BimModelContextProvider>
                  </SelectedPhaseProvider>
                </ProjectRoleContextProvider>
              </ProjectContextProvider>
            </LotContextProvider>
          }>
          {/* fullscreen */}
          <Route
            path={`${pagesUrl.PROJECT_PAGE}/:projectId/fullscreen/:fullScreenToken`}
            element={
              <CodesExtraitsListContextProvider>
                <CodesCompletionsContextProvider>
                  <CalculationCarbonMaterialFilterContextProvider>
                    <CodeAcvCompletionContextProvider>
                      <CodeCustomContextProvider>
                        <CalculationCarbonExtractedDataProvider>
                          <CalculationCarbonAdditionalDataProvider>
                            <MaterialLibFilterContextProvider>
                              <MaterialLibSearchContextProvider>
                                <MaterialLibContextProvider>
                                  <IniesRecordContextProvider>
                                    <ProjectMaterialContextProvider>
                                      <FullScreenViewer />
                                    </ProjectMaterialContextProvider>
                                  </IniesRecordContextProvider>
                                </MaterialLibContextProvider>
                              </MaterialLibSearchContextProvider>
                            </MaterialLibFilterContextProvider>
                          </CalculationCarbonAdditionalDataProvider>
                        </CalculationCarbonExtractedDataProvider>
                      </CodeCustomContextProvider>
                    </CodeAcvCompletionContextProvider>
                  </CalculationCarbonMaterialFilterContextProvider>
                </CodesCompletionsContextProvider>
              </CodesExtraitsListContextProvider>
            }
          />
          {/* Control Board */}
          <Route path={pagesUrl.PROJECT_CONTROL_BOARD} element={<ProjectControlBoard />} />
          {/*End Control Board*/}

          {/* project infos */}
          <Route
            path={`${pagesUrl.PROJECT_CONTROL_BOARD}${pagesUrl.PROJECT_INFO}`}
            element={
              <DepartmentContextProvider>
                <ProjectForm />
              </DepartmentContextProvider>
            }
          />

          {/* create project*/}
          <Route
            path={`${pagesUrl.PROJECT_PAGE}/:projectId${pagesUrl.PROJECT_INFO}`}
            element={
              <DepartmentContextProvider>
                <ProjectForm />
              </DepartmentContextProvider>
            }
          />
          {/* End project infos */}

          {/* project team infos */}
          <Route path={pagesUrl.PROJECT_TEAM_MANAGEMENT} element={<TeamPage />} />
          <Route path={pagesUrl.PROJECT_TEAM_CREATION} element={<TeamPage />} />
          {/* End project team infos */}
          {/* project material lib */}
          <Route
            path={pagesUrl.PROJECT_MATERIAL_PAGE}
            element={
              <MaterialLibFilterContextProvider>
                <MaterialLibSearchContextProvider>
                  <MaterialLibContextProvider>
                    <IniesRecordContextProvider>
                      <MaterialLibOrganizationIndicatorContextProvider>
                        <MaterialLibProjectIndicatorContextProvider>
                          <MaterialLibDeclarationTypeContextProvider>
                            <ProjectMaterialLibPage />
                          </MaterialLibDeclarationTypeContextProvider>
                        </MaterialLibProjectIndicatorContextProvider>
                      </MaterialLibOrganizationIndicatorContextProvider>
                    </IniesRecordContextProvider>
                  </MaterialLibContextProvider>
                </MaterialLibSearchContextProvider>
              </MaterialLibFilterContextProvider>
            }
          />
          {/* End project material lib */}
          {/* project phase stepper */}
          <Route path={pagesUrl.PROJECT_PHASE_STEPPER} element={<ProjectPhaseStepper />} />
          <Route path={pagesUrl.PROJECT_PHASE_STEPPER_CREATION} element={<ProjectPhaseStepper />} />
          {/* End project phase stepper */}
          {/* bim model */}
          <Route
            path={`${pagesUrl.PROJECT_PAGE}/:projectId/bim-model`}
            element={
              <BimModelImportContextProvider>
                <Outlet />
              </BimModelImportContextProvider>
            }>
            <Route path={pagesUrl.PROJECT_BIM_MODEL_IMPORT_TUTO} element={<TutorialPage />} />
            <Route path={pagesUrl.PROJECT_BIM_MODEL_IMPORT} element={<BimModelUploadPage />} />
          </Route>
          {/* End bim model */}
          {/* Calculation */}
          <Route
            path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}`}
            element={
              <CodesExtraitsListContextProvider>
                <CodesCompletionsContextProvider>
                  <CalculationCarbonMaterialFilterContextProvider>
                    <CodeAcvCompletionContextProvider>
                      <CodeCustomContextProvider>
                        <CalculationCarbonExtractedDataProvider>
                          <CalculationCarbonAdditionalDataProvider>
                            <MaterialLibFilterContextProvider>
                              <MaterialLibSearchContextProvider>
                                <MaterialLibContextProvider>
                                  <IniesRecordContextProvider>
                                    <ProjectMaterialContextProvider>
                                      <Outlet />
                                    </ProjectMaterialContextProvider>
                                  </IniesRecordContextProvider>
                                </MaterialLibContextProvider>
                              </MaterialLibSearchContextProvider>
                            </MaterialLibFilterContextProvider>
                          </CalculationCarbonAdditionalDataProvider>
                        </CalculationCarbonExtractedDataProvider>
                      </CodeCustomContextProvider>
                    </CodeAcvCompletionContextProvider>
                  </CalculationCarbonMaterialFilterContextProvider>
                </CodesCompletionsContextProvider>
              </CodesExtraitsListContextProvider>
            }>
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}`}
              element={
                <DepartmentContextProvider>
                  <CalculationCarbonInformationProjectContextProvider>
                    <GeneralInformationPage />
                  </CalculationCarbonInformationProjectContextProvider>
                </DepartmentContextProvider>
              }
            />
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}/flate-rate-data`}
              element={
                <CalculationCarbonFlateRateProvider>
                  <FlateRatesPage />
                </CalculationCarbonFlateRateProvider>
              }
            />
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}/extracted-data`}
              element={<ExtractedCodePage />}
            />
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}/additional-data`}
              element={<AdditionalCodePage />}
            />
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}/worksite`}
              element={<WorksitePage />}
            />
          </Route>
          {/* End Calculation */}
          {/* Dashboard */}
          <Route
            path={pagesUrl.PROJECT_DASHBOARD}
            element={
              <CodesExtraitsListContextProvider>
                <Outlet />
              </CodesExtraitsListContextProvider>
            }>
            <Route path={pagesUrl.PROJECT_DASHBOARD} element={<DashboardsPage />} />
          </Route>
          {/* End Dashboard */}
        </Route>
      </Route>

      <Route
        element={
          <RequireAuth>
            <OrganizationContextProvider>
              <SideBarContextProvider>
                <NavBarContextProvider>
                  <BSLayout />
                </NavBarContextProvider>
              </SideBarContextProvider>
            </OrganizationContextProvider>
          </RequireAuth>
        }>
        <Route
          path={pagesUrl.HOME}
          element={
            <BSVariantContextProvider>
              <HomePage />
            </BSVariantContextProvider>
          }
        />
      </Route>

      {RseeRoutes}
      {/* End user part */}

      {/* BEEM Shot part */}
      {BSRoutes}
      {/* End BEEM Shot part */}

      {/* Admin/Ultimate user part*/}
      <Route
        path={adminPagesUrl.ADMIN}
        element={
          <RequireAuthUltimate>
            <OrganizationsListContextProvider>
              <AdminOrganizationContextProvider>
                <DomainNameValidatorContextProvider>
                  <Layout mainMenu={adminMenu} />
                </DomainNameValidatorContextProvider>
              </AdminOrganizationContextProvider>
            </OrganizationsListContextProvider>
          </RequireAuthUltimate>
        }>
        <Route path={adminPagesUrl.ADMIN_ORGANIZATION_LIST_PAGE} element={<OrganizationList />} />
        <Route path={adminPagesUrl.ADMIN_ORGANIZATION_USER_PAGE} element={<AdminUpdateUserForm />} />
        <Route path={adminPagesUrl.ADMIN_INVITED_USERS_LIST_PAGE} element={<TestUsersList />} />
        <Route path={adminPagesUrl.ADMIN_SUMMARY} element={<AdminStatistics />} />

        <Route
          element={
            <RequireAuthUltimate>
              <AdminOrganizationContextProvider>
                <LayoutAdmin />
              </AdminOrganizationContextProvider>
            </RequireAuthUltimate>
          }>
          {/*Detail of an organization  */}
          <Route path={adminPagesUrl.ADMIN_ORGANIZATION_PAGE} element={<OrganizationPage />} />

          {/*Detail of the users in a organization    */}
          <Route
            path={adminPagesUrl.ADMIN_USER_ORGANIZATION_PAGE}
            element={
              <DomainNameValidatorContextProvider>
                <UserTableOrganizationPage />
              </DomainNameValidatorContextProvider>
            }
          />

          {/* Material lib */}
          <Route
            path={adminPagesUrl.MY_MATERIAL_PAGE}
            element={
              <MaterialLibFilterContextProvider>
                <MaterialLibSearchContextProvider>
                  <MaterialLibContextProvider>
                    <IniesRecordContextProvider>
                      <MaterialLibOrganizationIndicatorContextProvider>
                        <MaterialLibProjectIndicatorContextProvider>
                          <MaterialLibDeclarationTypeContextProvider>
                            <OrganizationMaterialLibPage />
                          </MaterialLibDeclarationTypeContextProvider>
                        </MaterialLibProjectIndicatorContextProvider>
                      </MaterialLibOrganizationIndicatorContextProvider>
                    </IniesRecordContextProvider>
                  </MaterialLibContextProvider>
                </MaterialLibSearchContextProvider>
              </MaterialLibFilterContextProvider>
            }
          />
          {/* End material lib */}

          {/* RSEE project */}
          <Route path={adminPagesUrl.PROJECT_RSEE_PAGE} element={<RseeProjectOrganizationPage />} />

          <Route
            path={adminPagesUrl.RSEE_PROJECT}
            element={
              <RseeProjectContextProvider>
                <Outlet />
              </RseeProjectContextProvider>
            }>
            <Route path={adminPagesUrl.RSEE_PROJECT} element={<RseeDocumentListPage />} />
            <Route path={adminPagesUrl.RSEE_PROJECT_GROUP_UPDATE} element={<RseeGroupPage />} />
            <Route
              path={adminPagesUrl.RSEE_PROJECT_DOCUMENT}
              element={
                <RseeDocumentContextProvider>
                  <Outlet />
                </RseeDocumentContextProvider>
              }>
              <Route path={adminPagesUrl.RSEE_PROJECT_DOCUMENT} element={<RseeDocumentDashboard />} />
              <Route path={adminPagesUrl.RSEE_PROJECT_DOCUMENT_GROUP_UPDATE} element={<RseeGroupPage />} />
            </Route>
          </Route>
          {/* End rsee project*/}

          <Route
            path={`${adminPagesUrl.ADMIN_ORGANIZATION_INVITE_USER_PAGE}`}
            element={
              <RequireOrganization>
                <DomainNameValidatorContextProvider>
                  <AdminInviteUserForm />
                </DomainNameValidatorContextProvider>
              </RequireOrganization>
            }
          />
          <Route path={`${adminPagesUrl.ADMIN_ORGANIZATION_INVITE_FIRST_MEGA_USER}`} element={<AdminInviteUserForm />} />
          <Route
            path={`${adminPagesUrl.ADMIN_ORGANIZATION_INVITE_FIRST_MEGA_USER}`}
            element={
              <RequireOrganization>
                <DomainNameValidatorContextProvider>
                  <AdminInviteUserForm />
                </DomainNameValidatorContextProvider>
              </RequireOrganization>
            }
          />

          {/* Management BIM Project */}
          <Route path={adminPagesUrl.BIM_PROJECT_PAGE} element={<BimProjectOrganizationPage />} />
          <Route
            path={`${adminPagesUrl.BIM_PROJECT_PAGE}/:projectId`}
            element={
              <LotContextProvider>
                <ProjectContextProvider>
                  <ProjectRoleContextProvider>
                    <SelectedPhaseProvider>
                      <BimModelContextProvider>
                        <BimModelFileContextProvider>
                          <TutorialEnabledContextProvider>
                            <CalculationContextProvider>
                              <VariantContextProvider>
                                <Outlet />
                              </VariantContextProvider>
                            </CalculationContextProvider>
                          </TutorialEnabledContextProvider>
                        </BimModelFileContextProvider>
                      </BimModelContextProvider>
                    </SelectedPhaseProvider>
                  </ProjectRoleContextProvider>
                </ProjectContextProvider>
              </LotContextProvider>
            }>
            <Route path={adminPagesUrl.PROJECT_CONTROL_BOARD} element={<ProjectControlBoard />} />
            <Route path={adminPagesUrl.PROJECT_INFO} element={<ProjectForm />} />
            <Route path={adminPagesUrl.PROJECT_TEAM_MANAGEMENT} element={<TeamPage />} />

            {/* project material lib */}
            <Route
              path={adminPagesUrl.PROJECT_MATERIAL_PAGE}
              element={
                <MaterialLibFilterContextProvider>
                  <MaterialLibSearchContextProvider>
                    <MaterialLibContextProvider>
                      <IniesRecordContextProvider>
                        <MaterialLibOrganizationIndicatorContextProvider>
                          <MaterialLibProjectIndicatorContextProvider>
                            <MaterialLibDeclarationTypeContextProvider>
                              <ProjectMaterialLibPage />
                            </MaterialLibDeclarationTypeContextProvider>
                          </MaterialLibProjectIndicatorContextProvider>
                        </MaterialLibOrganizationIndicatorContextProvider>
                      </IniesRecordContextProvider>
                    </MaterialLibContextProvider>
                  </MaterialLibSearchContextProvider>
                </MaterialLibFilterContextProvider>
              }
            />
            {/* End project material lib */}
            {/* bim model */}
            <Route
              path={`${adminPagesUrl.BIM_PROJECT_PAGE}/:projectId/bim-model`}
              element={
                <BimModelImportContextProvider>
                  <Outlet />
                </BimModelImportContextProvider>
              }>
              <Route path={adminPagesUrl.ADMIN_PROJECT_BIM_MODEL_IMPORT} element={<BimModelUploadPage />} />
            </Route>
            {/* End bim model */}

            {/* Calculation */}
            <Route
              path={adminPagesUrl.PROJECT_CALCULATION_CORE_DATA}
              element={
                <CodesExtraitsListContextProvider>
                  <CodesCompletionsContextProvider>
                    <CalculationCarbonMaterialFilterContextProvider>
                      <CodeAcvCompletionContextProvider>
                        <CodeCustomContextProvider>
                          <CalculationCarbonExtractedDataProvider>
                            <CalculationCarbonAdditionalDataProvider>
                              <MaterialLibFilterContextProvider>
                                <MaterialLibSearchContextProvider>
                                  <MaterialLibContextProvider>
                                    <IniesRecordContextProvider>
                                      <ProjectMaterialContextProvider>
                                        <Outlet />
                                      </ProjectMaterialContextProvider>
                                    </IniesRecordContextProvider>
                                  </MaterialLibContextProvider>
                                </MaterialLibSearchContextProvider>
                              </MaterialLibFilterContextProvider>
                            </CalculationCarbonAdditionalDataProvider>
                          </CalculationCarbonExtractedDataProvider>
                        </CodeCustomContextProvider>
                      </CodeAcvCompletionContextProvider>
                    </CalculationCarbonMaterialFilterContextProvider>
                  </CodesCompletionsContextProvider>
                </CodesExtraitsListContextProvider>
              }>
              <Route
                path={adminPagesUrl.PROJECT_CALCULATION_CORE_DATA}
                element={
                  <CalculationCarbonInformationProjectContextProvider>
                    <GeneralInformationPage />
                  </CalculationCarbonInformationProjectContextProvider>
                }
              />
              <Route
                path={adminPagesUrl.PROJECT_CALCULATION_FLAT_RATES}
                element={
                  <CalculationCarbonFlateRateProvider>
                    <FlateRatesPage />
                  </CalculationCarbonFlateRateProvider>
                }
              />
              <Route path={adminPagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA} element={<ExtractedCodePage />} />
              <Route path={adminPagesUrl.PROJECT_CALCULATION_ADDITIONAL_DATA} element={<AdditionalCodePage />} />
              <Route path={adminPagesUrl.PROJECT_CALCULATION_WORKSITE} element={<WorksitePage />} />
            </Route>
            {/* End Calculation */}

            {/* Dashboard */}
            <Route
              path={adminPagesUrl.ADMIN_PROJECT_DASHBOARD}
              element={
                <CodesExtraitsListContextProvider>
                  <Outlet />
                </CodesExtraitsListContextProvider>
              }>
              <Route path={adminPagesUrl.ADMIN_PROJECT_DASHBOARD_TABLEAU} element={<DashboardsPage />} />
            </Route>
          </Route>
          {/* End Dashboard */}
        </Route>
      </Route>
      {/* End admin/Ultimate user part*/}
      <Route path="*" element={<NoMatch />} />
    </Route>
  )
)

export default function AppRouter(): React.JSX.Element {
  return <RouterProvider router={router} />
}
