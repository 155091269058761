import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import React from "react"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSMaterialLib } from "./BSMaterialLib"

interface IProps {
  selectedBsMaterialResult: BSMaterialResult
  open: boolean

  handleClose(): void
}

export function BSMaterialLibDialog({ handleClose, open, selectedBsMaterialResult }: IProps): React.JSX.Element {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          minHeight: 400,
        },
      }}>
      <DialogTitle display="flex" justifyContent="center">
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "36px",
            textAlign: "center",
          }}>
          Importer une déclaration
        </Typography>
      </DialogTitle>
      <DialogContent>
        <BSMaterialLib handleClose={handleClose} selectedBsMaterialResult={selectedBsMaterialResult} />
      </DialogContent>
    </Dialog>
  )
}
