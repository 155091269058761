import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"
import { BSProjectCardDto } from "../../../dto/beem-shot/BSProject/BSProjectCardDto"
import { BSProjectCardWithSizeDto } from "../../../dto/beem-shot/BSProject/BSProjectCardWithSizeDto"
import { ProjectStatusEnum } from "../../../enum/projectStatusEnum"
import { useBSProject } from "../../../hooks/beem-shot/useBSProject"

export const BSProjectListContext = React.createContext<BSProjectListStore>({} as BSProjectListStore)

export const BS_PROJECT_ITEMS_PER_PAGE = 6

export function BSProjectListContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { getBSProjects } = useBSProject()

  const [bsProjectList, setBsProjectList] = useState<BSProjectCardDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [statusFilter, setStatusFilter] = useState<ProjectStatusEnum>(ProjectStatusEnum.ALL)

  const [projectCount, setProjectCount] = useState<number>(0)

  const [lastProjectId, setLastProjectId] = useState<string | undefined>(undefined)

  const [searchState, setSearchState] = useState<string>("")

  const [page, setPage] = useState(1)

  const isOneProjectCreated = useRef<boolean>(false)

  const fetchBSProjectCard = useCallback(
    (actualPage: number, currentStatusFilter: ProjectStatusEnum, limit = BS_PROJECT_ITEMS_PER_PAGE) => {
      setIsLoading(true)
      return getBSProjects(currentStatusFilter, actualPage, limit, searchState)
        .then((newBSProjectList: BSProjectCardWithSizeDto) => {
          setBsProjectList(newBSProjectList.projectCardDtoList)
          setProjectCount(newBSProjectList.projectCount)
          if (!isOneProjectCreated.current && newBSProjectList.projectCardDtoList.length > 0) {
            isOneProjectCreated.current = true
          }
          setLastProjectId(newBSProjectList.projectCardDtoList[newBSProjectList.projectCardDtoList.length - 1].id)
        })
        .finally(() => {
          setIsLoading(false)
          setIsInitialized(true)
        })
    },
    [getBSProjects, searchState]
  )

  useEffect(
    () => {
      fetchBSProjectCard(page, statusFilter)
    },
    // fetchBsProjectCard
    [page, statusFilter]
  )

  const triggerSearch = useCallback((): void => {
    if (page !== 1) {
      // Updating the page will trigger a refresh by the useEffect above
      setPage(1)
    } else {
      fetchBSProjectCard(1, statusFilter)
    }
  }, [page, fetchBSProjectCard, statusFilter])

  const bsProjectListStore = useMemo(
    () => ({
      bsProjectList,
      setBsProjectList,
      isLoading,
      setIsLoading,
      statusFilter,
      setStatusFilter,
      isOneProjectCreated,
      lastProjectId,
      projectCount,
      searchState,
      setSearchState,
      page,
      setPage,
      isInitialized,
      triggerSearch,
    }),
    [bsProjectList, isLoading, statusFilter, lastProjectId, projectCount, searchState, page, isInitialized, triggerSearch]
  )
  return <BSProjectListContext.Provider value={bsProjectListStore}>{children}</BSProjectListContext.Provider>
}

export type BSProjectListStore = {
  bsProjectList: BSProjectCardDto[]
  setBsProjectList: Dispatch<SetStateAction<BSProjectCardDto[]>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  statusFilter: ProjectStatusEnum
  setStatusFilter: Dispatch<SetStateAction<ProjectStatusEnum>>
  isOneProjectCreated: React.MutableRefObject<boolean>
  lastProjectId: string | undefined
  projectCount: number
  searchState: string
  setSearchState: Dispatch<SetStateAction<string>>
  page: number
  setPage: Dispatch<SetStateAction<number>>
  isInitialized: boolean
  triggerSearch(): void
}
