/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Breadcrumbs, Link, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { adminPagesUrl, pagesUrl } from '../../../../core/appConstants'
import { ProjectContext, ProjectStore } from '../../../../core/context/project/project-context'
import { ProjectRoleContext } from '../../../../core/context/user/project-role-context'
import { CalculationStepsEnum } from '../../../../core/enum/calculationStepsEnum'
import { resolveUrl } from '../../../../core/services/http-service'
import '../styles.scss'

type ProjectCarbonBreadcrumbProps = {
  selected: CalculationStepsEnum
  submit: () => Promise<boolean>
  isWriting?: boolean
}

export default function ProjectCarbonBreadcrumb({
  selected,
  submit,
  isWriting = true,
}: ProjectCarbonBreadcrumbProps): React.JSX.Element {
  const navigate = useNavigate()
  const { project } = useContext<ProjectStore>(ProjectContext)
  const { isOrganizationUltimateUser } = useContext(ProjectRoleContext)
  const { organizationId } = useParams()

  const stepperUrl = {
    stepOne: isOrganizationUltimateUser
      ? resolveUrl(adminPagesUrl.PROJECT_CALCULATION_CORE_DATA, [organizationId, project?.id])
      : resolveUrl(pagesUrl.PROJECT_CALCULATION_CORE_DATA, [project?.id]),
    setepTwo: isOrganizationUltimateUser
      ? resolveUrl(adminPagesUrl.PROJECT_CALCULATION_FLAT_RATES, [organizationId, project?.id])
      : resolveUrl(pagesUrl.PROJECT_CALCULATION_FLAT_RATES, [project?.id]),
    stepThree: isOrganizationUltimateUser
      ? resolveUrl(adminPagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA, [organizationId, project?.id])
      : resolveUrl(pagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA, [project?.id]),
    stepFour: isOrganizationUltimateUser
      ? resolveUrl(adminPagesUrl.PROJECT_CALCULATION_ADDITIONAL_DATA, [organizationId, project?.id])
      : resolveUrl(pagesUrl.PROJECT_CALCULATION_ADDITIONAL_DATA, [project?.id]),
    stepFive: isOrganizationUltimateUser
      ? resolveUrl(adminPagesUrl.PROJECT_CALCULATION_WORKSITE, [organizationId, project?.id])
      : resolveUrl(pagesUrl.PROJECT_CALCULATION_WORKSITE, [project?.id]),
  }

  function onNavigate(url: string): void {
    if (isWriting) {
      submit().then((success) => {
        if (success) {
          navigate(url)
        }
      })
    } else {
      navigate(url)
    }
  }

  return (
    <Breadcrumbs separator="__" aria-label="breadcrumb">
      <Link
        underline="none"
        component="button"
        color="inherit"
        style={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => onNavigate(stepperUrl.stepOne)}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          color: selected === CalculationStepsEnum.INFO ? "black" : "",
        }}>
        <Avatar
          sx={{
            backgroundColor: selected === CalculationStepsEnum.INFO ? "black" : "#acacac",
            mr: 1,
            mt: 0.3,
            width: 25,
            height: 25,
            fontSize: 12,
          }}>
          1
        </Avatar>
        <Typography>Informations générales</Typography>
      </Link>
      <Link
        component="button"
        underline="none"
        color="inherit"
        onClick={() => onNavigate(stepperUrl.setepTwo)}
        display="flex"
        style={{ cursor: "pointer" }}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          color: selected === CalculationStepsEnum.FLAT_RATES ? "black" : "",
        }}>
        <Avatar
          sx={{
            backgroundColor: selected === CalculationStepsEnum.FLAT_RATES ? "black" : "#acacac",
            mr: 1,
            mt: 0.3,
            width: 25,
            height: 25,
            fontSize: 12,
          }}>
          2
        </Avatar>
        <Typography>Données conventionnelles</Typography>
      </Link>

      <Link
        underline="none"
        color="inherit"
        component="button"
        display="flex"
        style={{ cursor: "pointer" }}
        onClick={() => onNavigate(stepperUrl.stepThree)}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          color: selected === CalculationStepsEnum.EXTRACTED_CODE ? "black" : "",
        }}>
        <Avatar
          sx={{
            backgroundColor: selected === CalculationStepsEnum.EXTRACTED_CODE ? "black" : "#acacac",
            mr: 1,
            mt: 0.3,
            width: 25,
            height: 25,
            fontSize: 12,
          }}>
          3
        </Avatar>
        <Typography>Données extraites</Typography>
      </Link>
      <Link
        underline="none"
        color="inherit"
        component="button"
        onClick={() => onNavigate(stepperUrl.stepFour)}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          color: selected === CalculationStepsEnum.ADDITIONAL_CODE ? "black" : "",
        }}>
        <Avatar
          sx={{
            backgroundColor: selected === CalculationStepsEnum.ADDITIONAL_CODE ? "black" : "#acacac",
            mr: 1,
            mt: 0.3,
            width: 25,
            height: 25,
            fontSize: 12,
          }}>
          4
        </Avatar>
        <Typography>Données supplémentaires</Typography>
      </Link>
      <Link
        underline="none"
        color="inherit"
        component="button"
        onClick={() => onNavigate(stepperUrl.stepFive)}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          color: selected === CalculationStepsEnum.WORKSITE ? "black" : "",
        }}>
        <Avatar
          sx={{
            backgroundColor: selected === CalculationStepsEnum.WORKSITE ? "black" : "#acacac",
            mr: 1,
            mt: 0.3,
            width: 25,
            height: 25,
            fontSize: 12,
          }}>
          5
        </Avatar>
        <Typography>Chantier</Typography>
      </Link>
    </Breadcrumbs>
  )
}
