import { Box } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { TableauViz } from "../../../core/services/tableau/tableau-service"
import { useKairnial } from "../../kairnial/useKairnial"
import { TableauDashboard } from "../../../components/TableauDashboard/TableauDashboard"

export function KairnialGeneralDashboard(): React.JSX.Element {
  const { fetchIcadeDashboardUrl } = useKairnial()

  const [dashboardUrl, setDashboardUrl] = useState<string>("")
  const viz: React.MutableRefObject<any> = useRef<any>(new TableauViz())

  useEffect(() => {
    fetchIcadeDashboardUrl().then((newDashboardUrl) => {
      setDashboardUrl(newDashboardUrl)
    })
  }, [fetchIcadeDashboardUrl])

  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
      {dashboardUrl && (
        <TableauDashboard dashboardUrl={dashboardUrl} isDataReady tableauParameter={[]} vizRef={viz} filterToAdd={[]} />
      )}
    </Box>
  )
}
