import { Box, CircularProgress, Grid } from "@mui/material"
import React, { FormEvent, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BSSubmitDialog } from "../../../../components/dialog/BSSubmitDialog"
import { BSIfcDisplayer } from "../../../../components/ifc-displayer/bs-ifc-displayer"
import { ViewerTypes } from "../../../../components/ifc-displayer/enums/ViewerTypes"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { pagesUrl } from "../../../../core/appConstants"
import { BSModelFileContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSModelImportContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelImportContext"
import { BSItemContext } from "../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSProjectContext } from "../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { CodeReferenceContext } from "../../../../core/context/code-acv/CodeReferenceContext"
import { AppNavBarContext, NavbarTypeEnum } from "../../../../core/context/nav-bar/AppNavBarContext"
import { CodeExtraitDisplay } from "../../../../core/dto/code-extrait/CodeExtraitDisplay"
import { CalculStatusEnum } from "../../../../core/enum/calculStatusEnum"
import { useBSParseBim } from "../../../../core/hooks/beem-shot/useBSParseBim"
import { focusOnFormError } from "../../../../core/hooks/form/use-form"
import { codeToKey } from "../../../../core/services/code-service"
import { resolveUrl } from "../../../../core/services/http-service"
import { BSCancelDialog } from "../../components/Dialog/BSCancelDialog"
import { Questionnaires } from "./Questionnaires"
import { SimplifiedQuestionnaireContext, SimplifiedQuestionnaireProvider } from "./SimplifiedQuestionnaireContext"

export function BSCalculationForm(): React.JSX.Element {
  return (
    <SimplifiedQuestionnaireProvider>
      <BSCalculationFormCore />
    </SimplifiedQuestionnaireProvider>
  )
}

function BSCalculationFormCore(): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId } = useParams()
  const { bsVariantId } = useParams()

  const openErrorSnackbar = useContext(ErrorContext)
  const { file } = useContext(BSModelFileContext)
  const { selectedCodeExtrait, setSelectedCodeExtrait, selectedBSItem, setSelectedBSItem, bsItemsForSubCategory } =
    useContext(BSItemContext)
  const { bsProject, startEstimating } = useContext(BSProjectContext)
  const { codesExtraits, setCodesExtraits, viewer, setViewer, setTypesToElementIdsMap } = useContext(BSModelImportContext)
  const { validate, clearOnDestroy, errors } = useContext(SimplifiedQuestionnaireContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)
  const { isCodeAcvLoading } = useContext(CodeReferenceContext)
  const { updateAllBsItems } = useContext(BSItemContext)
  const { selectedVariant, updateCalculStatus } = useContext(BSVariantContext)
  const needsToChangeTab = useRef<boolean>(false)
  const needsToStartEstimating = useRef<boolean>(false)
  const isRefreshingBSItems = useRef<boolean>(false)

  const [progress, setProgress] = useState<number>(0)
  const { analyzeModel } = useBSParseBim({
    version: bsProject?.codesVersion,
    setProgress,
    setTypesToElementIdsMap,
    setViewer,
    setCodesExtraits,
  })

  const [bimModelFile, setBimModelFile] = useState<File | undefined>(file)
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [isCancelProjectOpen, setIsCancelProjectOpen] = useState<boolean>(false)
  const [isCalculating, setIsCalculating] = useState<boolean>(false)

  useEffect(() => {
    if (file) {
      setBimModelFile(file)
    }
  }, [file])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_CONTROL, [bsProjectId, bsVariantId]))
    setStepHeader(2)
  }, [bsProjectId, bsVariantId, setPreviousUrl, setStepHeader, setTypeNavBar])

  useEffect(() => {
    if (progress !== 100) {
      document.body.style.cursor = "wait"
    } else {
      document.body.style.cursor = "default"
    }

    return () => {
      document.body.style.cursor = "default"
    }
  }, [progress])

  useEffect(() => {
    if (selectedCodeExtrait !== undefined && selectedBSItem !== undefined) {
      const ref: any = document.getElementById(selectedBSItem.id)
      if (ref) {
        ref.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }, [selectedCodeExtrait, selectedBSItem, bsItemsForSubCategory])

  function reset(): void {
    setBimModelFile(undefined)
    setCodesExtraits([])
  }

  const handleClickAcv = useCallback(
    (codeExtrait: CodeExtraitDisplay, disableViewerHilight?: boolean) => {
      if (
        !disableViewerHilight &&
        `${codeExtrait.code + codeExtrait.occurence}` !==
          `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ""}`
      )
        viewer?.manager.subsetsManager.highlightCodeExtrait(codeExtrait)

      const newCode = codesExtraits.find((code) => codeToKey(code) === codeToKey(codeExtrait))
      setSelectedCodeExtrait(newCode)
    },
    [selectedCodeExtrait, viewer?.manager.subsetsManager, codesExtraits]
  )

  function cancelUpload(): void {
    setBimModelFile(undefined)
    setCodesExtraits([])
    setProgress(0)
  }

  function cancelAction(): void {
    setIsCancelProjectOpen(true)
  }

  function handleClose(): void {
    setIsCancelProjectOpen(false)
  }

  function handlePauseProject(): void {
    setIsCancelProjectOpen(false)
    return navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
  }

  function handleSubmit(event: FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>): void {
    event.preventDefault()
    submit()
  }

  function submit(): void {
    const isValid = validate()
    if (isValid && !isCalculating) {
      setIsCalculating(true)
      if (isRefreshingBSItems.current) {
        // We don't want to start estimating if bs items are being refreshed because this will cause a "mongo transaction conflict error"
        // But we want the circuling progress to be displayed
        needsToStartEstimating.current = true
        return
      }

      clearOnDestroy()
        .then(() => {
          updateCalculStatus(CalculStatusEnum.CALCUL_IN_PROGRESS)
        })
        .then(() => startEstimating())
        .then(() => {
          navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, selectedVariant?.id]), {
            state: { defaultTab: "TABLEAU_DE_BORD" },
          })
        })
        .finally(() => {
          setIsCalculating(false)
        })
        .catch(() => updateCalculStatus(CalculStatusEnum.WAITING_FOR_CONFIGURATION))
    } else {
      openErrorSnackbar(new Error("Vous devez corriger un des champs du questionnaire simplifié"))
    }
  }

  function changeTab(event: React.SyntheticEvent, newValue: number): void {
    if (progress === 100) {
      viewer?.manager.subsetsManager.restoreAllSubsetsMaterial()
      viewer?.manager.subsetsManager.changeType(
        newValue === 0 ? ViewerTypes.NO_HIGHLIGHT_CODE : ViewerTypes.CODE_VERIFICATION
      )

      const isFormValid = validate()

      if (!isFormValid) {
        if (newValue === 1 && bsProjectId) {
          openErrorSnackbar(new Error("Un des champs du questionnaire simplifié n'est pas valide"))

          // useRef in futur if problem with DOM
          if (Object.keys(errors).length > 0) {
            focusOnFormError(errors)
          }
        }
      } else if (newValue === 1 && bsVariantId) {
        isRefreshingBSItems.current = true
        clearOnDestroy()
          .then(() => {
            setSelectedTab(newValue)
            setSelectedCodeExtrait(undefined)
            setSelectedBSItem(undefined)
            return updateAllBsItems(bsVariantId)
          })
          .then(() => {
            if (needsToChangeTab.current) {
              setSelectedTab(0)
              setSelectedCodeExtrait(undefined)
              setSelectedBSItem(undefined)
              needsToChangeTab.current = false
            }
          })
          .finally(() => {
            isRefreshingBSItems.current = false
            if (needsToStartEstimating.current) {
              needsToStartEstimating.current = false
              submit()
            }
          })
      } else if (isRefreshingBSItems.current) {
        needsToChangeTab.current = true
      } else {
        setSelectedTab(newValue)
        setSelectedCodeExtrait(undefined)
        setSelectedBSItem(undefined)
      }
    }
  }

  return (
    <>
      <Box id="Content">
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={5} id="questionnaire" component="form" onSubmit={handleSubmit}>
            <Questionnaires
              selectedTab={selectedTab}
              handleChange={changeTab}
              progress={progress}
              isSubmitting={isRefreshingBSItems.current}
            />
          </Grid>
          <Grid item xs={7}>
            {!isCodeAcvLoading && bimModelFile ? (
              <BSIfcDisplayer
                file={bimModelFile}
                reset={reset}
                onChangeModel={analyzeModel}
                setExternalProgress={setProgress}
                type={selectedTab === 0 ? ViewerTypes.NO_HIGHLIGHT_CODE : ViewerTypes.CODE_VERIFICATION}
                codesExtraits={selectedTab === 1 ? codesExtraits : []}
                showFileName
                setSelectedCodeExtrait={handleClickAcv}
                cancelUpload={cancelUpload}
                height="70vh"
              />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%" minHeight="100%">
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <CustomBottomNavigation
        actionLabel="Calculer l'impact carbone"
        formId="questionnaire"
        cancelLabel="Annuler"
        cancelAction={cancelAction}
        isSubmiting={isCalculating}
      />
      <BSCancelDialog
        bsProjectId={bsProjectId}
        bsProjectName={bsProject?.name}
        bsVariantId={bsVariantId}
        handleClose={handlePauseProject}
        isOpen={isCancelProjectOpen}
      />
      <BSSubmitDialog
        isOpen={isCancelProjectOpen}
        onClose={handleClose}
        onSubmit={handlePauseProject}
        titleText="Êtes-vous sûr ?"
        contentText={
          <>
            Vous souhaitez remettre à plus tard la création/mise à jour du projet "{bsProject?.name}" ? <br />
            Vous pouvez reprendre son édition à tout moment depuis la liste des projets Beem Shot.
          </>
        }
        cancelButtonLabel="Annuler"
        submitButtonLabel="Mettre en pause"
      />
    </>
  )
}
