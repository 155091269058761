import PersonIcon from "@mui/icons-material/Person"
import WebStoriesIcon from "@mui/icons-material/WebStories"
import { Avatar, Box, Button, Typography } from "@mui/material"
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import React, { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { adminPagesUrl, pagesUrl, appConstants } from "../../../core/appConstants"
import { UserStore, UserContext } from "../../../core/context/user/user-context"
import { ProjectCardDto } from "../../../core/dto/project/project-card-dto"
import { ProjectTypeEnum } from "../../../core/enum/project/projectTypeEnum"
import { OrganizationRoleEnum } from "../../../core/enum/user/organization-role-enum"
import { useProjects } from "../../../core/hooks/projects/use-projects"
import { displayNumber } from "../../../core/services/helper-service"
import { resolveUrl } from "../../../core/services/http-service"
import IconDefaultProjectCard from "../../custom-icons/icon-default-project-card"
import { ImageDisplayer } from "../../image-displayer/image-displayer"

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, annotationPlugin)

type IProps = {
  projectCardInfo: ProjectCardDto
  hasCoverImage: boolean
}

export default function ProjectCard({ projectCardInfo, hasCoverImage }: IProps): React.JSX.Element {
  const { fetchCoverImage } = useProjects()
  const { user } = useContext<UserStore>(UserContext)
  const { organizationId } = useParams()

  const [file, setFile] = useState<File | undefined>()

  useEffect(() => {
    if (projectCardInfo.fileName && projectCardInfo.awsFileKey) {
      fetchCoverImage(projectCardInfo.projectId).then(async (blob) => {
        if (blob) {
          const fileName = projectCardInfo.fileName
          const newFile = new File([blob], fileName, { type: projectCardInfo.fileType })
          setFile(newFile)
        }
      })
    }
  }, [])

  function getNavigateUrl(): string {
    if (user?.role === OrganizationRoleEnum.ULTIMATE_USER) {
      return adminNavigation()
    } else {
      return normalNavigation()
    }
  }

  function adminNavigation(): string {
    if (projectCardInfo.type === ProjectTypeEnum.RSEE) {
      return resolveUrl(adminPagesUrl.RSEE_PROJECT, [organizationId, projectCardInfo.projectId])
    } else {
      return resolveUrl(adminPagesUrl.PROJECT_CONTROL_BOARD, [organizationId, projectCardInfo.projectId])
    }
  }

  function normalNavigation(): string {
    if (projectCardInfo.earlyProject) {
      return `${pagesUrl.PROJECT_PAGE}/${projectCardInfo.projectId}/control-board/dashboard/${projectCardInfo.powerBiId}`
    } else if (projectCardInfo.type === ProjectTypeEnum.RSEE) {
      return resolveUrl(pagesUrl.RSEE_PROJECT, [projectCardInfo.projectId])
    } else {
      return `${pagesUrl.PROJECT_PAGE}/${projectCardInfo.projectId}/control-board`
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        borderRadius: 3,
      }}>
      <Link to={getNavigateUrl()} style={{ width: "100%", minHeight: 100, textDecoration: "none", color: "inherit" }}>
        <Box
          sx={{
            borderRadius: 2,
            boxShadow: 2,
            width: "100%",
            minHeight: 100,
            mb: 4,
            p: 2,
            cursor: "pointer",
          }}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ flex: 1 }}>
            <Avatar
              className="notranslate"
              sx={{ backgroundColor: "black", mr: 2, mt: 0.5 }}
              alt={projectCardInfo?.projectName?.toLocaleUpperCase()}>
              {projectCardInfo?.projectName?.charAt(0).toUpperCase()}
            </Avatar>
            <Typography className="notranslate" fontWeight="bold" noWrap textOverflow="ellipsis" sx={{ flex: 1 }}>
              {projectCardInfo?.projectName}
            </Typography>
            {projectCardInfo.type !== ProjectTypeEnum.RSEE && (
              <Box display="flex">
                <PersonIcon />
                <Typography fontWeight="bold" sx={{ ml: 0.5 }}>
                  {projectCardInfo?.teamNumber || 0}
                </Typography>
              </Box>
            )}
          </Box>
          {hasCoverImage && (
            <Box display="flex" justifyContent="center" sx={{ mt: 1, mb: 3 }}>
              {file ? (
                <ImageDisplayer
                  file={file}
                  width={appConstants.miscellaneous.PROJECT_IMG_WIDTH}
                  height={appConstants.miscellaneous.PROJECT_IMG_HEIGTH}
                />
              ) : (
                <Box
                  width={appConstants.miscellaneous.PROJECT_IMG_WIDTH}
                  height={appConstants.miscellaneous.PROJECT_IMG_HEIGTH}
                  sx={{
                    border: 1,
                  }}>
                  <IconDefaultProjectCard
                    style={{
                      fontSize: "100%",
                      width: "100%",
                      height: "100%",
                      marginRight: "5px",
                      verticalAlign: "baseline",
                      display: "inline-block",
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          {!projectCardInfo.earlyProject && (
            <Box display="flex" justifyContent="space-between" sx={{ mt: 3, mb: 3 }}>
              {projectCardInfo?.phase !== undefined && (
                <Box>
                  <Typography fontSize={14} fontWeight="500">
                    PHASE DU PROJET
                  </Typography>
                  <Typography color="#CFCFCF" fontSize={14}>
                    {projectCardInfo.phase}
                  </Typography>
                </Box>
              )}
              {projectCardInfo?.surfaceRef !== undefined && (
                <Box display="flex">
                  <WebStoriesIcon sx={{ mt: 0.5 }} />
                  <Box sx={{ ml: 1.5 }}>
                    <Typography fontWeight="bold" fontSize={13}>
                      {projectCardInfo.surfaceRef}m²
                    </Typography>
                    <Typography textAlign="center" fontWeight="bold" fontSize={11}>
                      SREF
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {projectCardInfo?.projectOrganizationBusinessName !== undefined && (
            <Box>
              <Typography fontSize={14} fontWeight="500">
                PROPRIETAIRE
              </Typography>
              <Typography color="#CFCFCF" fontSize={14}>
                {projectCardInfo.projectOrganizationBusinessName.toUpperCase()}
              </Typography>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between" sx={{ mt: 3, mb: 3 }}>
            {!projectCardInfo.earlyProject && (
              <Box>
                <Typography fontWeight="500" fontSize={14}>
                  RE2020
                </Typography>
                <Typography color="#E9B36E" fontWeight="bold" fontSize={13}>
                  SEUIL 2022
                </Typography>
              </Box>
            )}
            {projectCardInfo.createdByUser &&
              (projectCardInfo.type === ProjectTypeEnum.RSEE ||
                projectCardInfo.type === ProjectTypeEnum.RSEE_ORGANIZATION) && (
                <Box>
                  <Typography fontSize={14} fontWeight="500">
                    CRÉÉ PAR
                  </Typography>
                  <Typography color="#CFCFCF" fontSize={14}>
                    {projectCardInfo.createdByUser}
                  </Typography>
                </Box>
              )}
            {projectCardInfo?.impactCarboneGlobal !== undefined && projectCardInfo?.seuilCarboneGlobal !== undefined && (
              <Box sx={{ ml: 1.5 }}>
                <Typography textAlign="center" color="#E9B36E" fontWeight="bold" fontSize={18}>
                  {displayNumber(projectCardInfo?.impactCarboneGlobal)}/{displayNumber(projectCardInfo?.seuilCarboneGlobal)}
                </Typography>
                <Typography fontWeight="bold" fontSize={12}>
                  kgeqCO2 / m²sref
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Link>

      <Link to={getNavigateUrl()}>
        <Button
          sx={{
            mb: 10,
          }}
          variant="contained">
          Ouvrir le projet
        </Button>
      </Link>
    </Box>
  )
}
