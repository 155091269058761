import { appConstants } from "../appConstants"
import { SelectOption } from "../../components/inputs/select-input/SelectInput"
import { OrganizationRoleEnum } from "../enum/user/organization-role-enum"

export function isPasswordValid(password: string): boolean {
  return (
    password.length > 7 &&
    appConstants.regex.passwordContainsLowerCase.test(password) &&
    appConstants.regex.passwordContainsUpperCase.test(password) &&
    appConstants.regex.passwordContainsSpecialChar.test(password)
  )
}

export function isPhoneValid(phoneNumber: string | undefined): boolean {
  if (!phoneNumber) {
    return false
  }

  if (phoneNumber.startsWith("+") && (phoneNumber.length === 12 || phoneNumber.length === 13)) {
    return appConstants.regex.onlyDigits.test(phoneNumber.substring(1))
  } else {
    return phoneNumber.length === 10 && appConstants.regex.onlyDigits.test(phoneNumber)
  }
}

export function isEmailValid(email: string): boolean {
  return appConstants.regex.email.test(email)
}

export function formatPhone(phone: string): string {
  let value: string = phone
  let maxLength = 10
  let startsWithPlus = false

  if (value.startsWith("+")) {
    startsWithPlus = true
    maxLength = 12
  }

  value = value.replace(appConstants.regex.notDigits, "")
  if (startsWithPlus) {
    value = "+".concat(value)
  }

  if (value.length > maxLength) {
    value = value.substring(0, maxLength)
  }

  return value
}

export const roleUpdateSelectOptions: SelectOption<OrganizationRoleEnum>[] = [
  {
    value: OrganizationRoleEnum.NORMAL_USER,
    label: "Membre",
  },
  {
    value: OrganizationRoleEnum.SUPER_USER,
    label: "Chef de projets",
  },
  {
    value: OrganizationRoleEnum.MEGA_USER,
    label: "Administrateur de l'organisation",
  },
]

export const roleSuperUserSelectOptions: SelectOption<OrganizationRoleEnum>[] = [
  {
    value: OrganizationRoleEnum.NORMAL_USER,
    label: "Membre",
  },
  {
    value: OrganizationRoleEnum.SUPER_USER,
    label: "Chef de projets",
  },
]

export const roleMyAccountSelectOptions: SelectOption<OrganizationRoleEnum>[] = [
  {
    value: OrganizationRoleEnum.NORMAL_USER,
    label: "Membre",
  },
  {
    value: OrganizationRoleEnum.SUPER_USER,
    label: "Chef de projets",
  },
  {
    value: OrganizationRoleEnum.MEGA_USER,
    label: "Administrateur de l'organisation",
  },
  {
    value: OrganizationRoleEnum.TEST_USER,
    label: "En version d'essai",
  },
]
