export enum SeuilEnum {
  SEUIL_2022 = "SEUIL_2022",
  SEUIL_2025 = "SEUIL_2025",
  SEUIL_2028 = "SEUIL_2028",
  SEUIL_2031 = "SEUIL_2031",
  INVALIDE = "INVALIDE",
}

export function getLabelText(value: SeuilEnum): string {
  switch (value) {
    case SeuilEnum.SEUIL_2022:
      return "Seuil 2022"
    case SeuilEnum.SEUIL_2025:
      return "Seuil 2025"
    case SeuilEnum.SEUIL_2028:
      return "Seuil 2028"
    case SeuilEnum.SEUIL_2031:
      return "Seuil 2031"
    case SeuilEnum.INVALIDE:
      return "Seuil dépassé"
    default:
      return ""
  }
}
