import { Dispatch, SetStateAction } from "react"
import { Box3, Color } from "three"
import { IFCSPACE } from "web-ifc"
import { ParserProgress } from "web-ifc-three/IFC/components/IFCParser"
import { IfcViewerAPI } from "web-ifc-viewer"
import { CodeExtraitDisplay } from "../../core/dto/code-extrait/CodeExtraitDisplay"
import { CodeExtrait } from "../../core/dto/code-extrait/code-extrait"
import { getEnvProperty, PROPERTIES } from "../../core/services/environment-service"
import { Size } from "../../core/type/Size"
import { ViewerIFCManager, ViewerIFCManagerProps } from "./ViewerIFCManager"
import { ViewerTypes } from "./enums/ViewerTypes"
import { SubsetInfo } from "./models/SubsetInfo"

export interface ViewerIFCProps {
  canvasRef?: HTMLCanvasElement | null
  size?: Size
  file?: File
  fileUrl?: string
  type: ViewerTypes
  setContentDrawer?: Dispatch<SetStateAction<string>>
  onChangeModel?: (modelId: number, ifc: ViewerIFC) => void
  codesExtraits: (CodeExtrait | CodeExtraitDisplay)[]
  setSelectedCodeExtrait: (code: CodeExtrait | CodeExtraitDisplay, disableViewerHilight?: boolean) => void
  openViewerMessage?: (message: string) => void
  setCodeManquantElements?: (codeManquantElements: SubsetInfo[]) => void
  setSelectedCodeManquantElement?: (codeManquant: SubsetInfo) => void
  updateIsInHidingIrellevantCodesState?: (isHide: boolean) => void
  updateIsInHideState?: (isHide: boolean) => void
  setViewerBarProgress?: Dispatch<SetStateAction<number>>

  setProgress(progress: number): void
}

export default class ViewerIFC {
  container: HTMLElement | null
  viewer!: IfcViewerAPI
  file: File | undefined
  fileUrl: string | undefined
  /** The manager of the viewer to control the subsets and cameras and other features */
  manager: ViewerIFCManager
  /** The model id of the loaded IFC file */
  modelId: number | undefined
  isLoadingFile = false
  setContentDrawer: Dispatch<SetStateAction<string>> | undefined
  setProgress: (progress: number) => void
  setViewerBarProgress: Dispatch<SetStateAction<number>> | undefined
  onChangeModel: ((modelId: number, ifc: ViewerIFC) => void) | undefined

  constructor(options: ViewerIFCProps) {
    this.setProgress = options.setProgress
    this.setContentDrawer = options.setContentDrawer
    this.onChangeModel = options.onChangeModel
    this.setViewerBarProgress = options.setViewerBarProgress
    this.file = options.file
    this.fileUrl = options.fileUrl
    this.container = options.canvasRef ?? document.getElementById("viewer-canvas")

    this.setViewer()

    if (getEnvProperty(PROPERTIES.REACT_APP_WASM_PATH) === undefined) {
      throw new Error("Le wasm path must have a value.")
    }

    this.viewer.IFC.setWasmPath(getEnvProperty(PROPERTIES.REACT_APP_WASM_PATH))

    this.setupWebWorkers()

    this.manager = new ViewerIFCManager({
      type: options.type,
      viewer: this.viewer,
      container: this.container,
      showMessage: options.openViewerMessage,
      codesExtraits: options.codesExtraits,
      setSelectedCodeExtrait: options.setSelectedCodeExtrait,
      setCodeManquantElements: options.setCodeManquantElements,
      setSelectedCodeManquantElement: options.setSelectedCodeManquantElement,
      setContentDrawer: options.setContentDrawer,
      updateIsInHidingIrellevantCodesState: options.updateIsInHidingIrellevantCodesState,
      updateIsInHideState: options.updateIsInHideState,
      setViewerBarProgress: options.setViewerBarProgress,
      setProgress: options.setProgress,
      modelId: this.modelId,
    } as ViewerIFCManagerProps)
  }

  setViewer(container?: HTMLElement): void {
    this.container = container ?? this.container
    if (!this.container) {
      throw new Error("container is null.")
    }
    try {
      this.viewer = new IfcViewerAPI({
        container: this.container,
        backgroundColor: new Color(0xffffff),
      })
    } catch (e) {
      console.error("Erreur webGL context")
      console.error("erreur:", e)
      console.error("this.container: ", this.container)
      console.error("this.viewer: ", this.viewer)
      console.error("this.file: ", this.file)
      console.error("this.manager: ", this.manager)
      console.error("this.modelId: ", this.modelId)
      throw e
    }

    // Set the right viewer config to not have a blurry model
    this.viewer.IFC.loader.ifcManager.applyWebIfcConfig({
      USE_FAST_BOOLS: true,
      COORDINATE_TO_ORIGIN: true,
    })
  }

  async loadIFC(file?: File | string): Promise<void> {
    this.fileUrl = file && typeof file === "string" ? file : this.fileUrl
    this.file = file && typeof file !== "string" ? file : this.file
    if (this.file) {
      this.fileUrl = URL.createObjectURL(this.file)
    }
    if (!this.fileUrl) {
      throw new Error("File is null.")
    }

    // Remove space type
    await this.viewer.IFC.loader.ifcManager.parser.setupOptionalCategories({
      [IFCSPACE]: false,
    })

    console.info("this.fileUrl", this.fileUrl)

    this.isLoadingFile = true
    this.viewer.IFC.loadIfcUrl(this.fileUrl, true).then((model: any) => {
      this.manager.measurements.boundingBox = model.geometry.boundingBox as Box3
      this.modelId = model.modelID
      if (this.onChangeModel) {
        this.onChangeModel(model.modelID, this)
      }
      this.viewer.context.getIfcCamera().cameraControls.saveState()
    })
  }

  getViewer(): IfcViewerAPI {
    return this.viewer
  }

  /**
   * These steps are taken to setup the web workers for IFC.js
   * 1. Copy the 'IFCWorker.js' and 'IFCWorker.js.map' from 'node_modules/web-ifc-three' to the 'public/static/js' folder
   * 2. Set the path of the 'IFCWorker.js' file in the 'useWebWorkers' method to be relative to the current file vs the index file
   * 3. Set the unified progress callback
   */
  setupWebWorkers(): void {
    // the 'IFCWorker.js' and 'IFCWorker.js.map' are both copied from 'node_modules/web-fic-three' to the 'public/static/js' folder
    this.viewer.IFC.loader.ifcManager.useWebWorkers(true, "/static/js/IFCWorker.js") //the path have to be relative to the current file vs the index file
    this.viewer.IFC.loader.ifcManager.setOnProgress((event: ParserProgress) => {
      const percent = (event.loaded / event.total) * 50
      const percentBar = (event.loaded / event.total) * 100
      if (this.setProgress) this.setProgress(percent)
      if (this.setViewerBarProgress) this.setViewerBarProgress(percentBar)
    })
  }

  delete(): void {
    this.manager.dispose()
    this.file = undefined
    this.fileUrl = undefined
    this.viewer
      ?.dispose()
      .then(() => {
        console.info("finish viewer.dispose()")
      })
      .catch((e) => {
        console.error("Error when terminating web worker:", e)
      })
  }
}
