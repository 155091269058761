import { Box, TableCell, TableRow, Typography } from "@mui/material"
import React from "react"
import { customPalette } from "../../../../../theme"
import { IniesRecord } from "../../../../core/dto/material/IniesRecord"
import { originMaterialRecordToLabel } from "../../../../core/dto/material/material"
import { MaterialRecord } from "../../../../core/dto/material/MaterialRecord"
import { DeclarationTypeEnum } from "../../../../core/enum/declarationTypeEnum"
import { getDeclarationTypeLabel } from "../../../../core/services/declaration-service"

type MaterialRowProps = {
  record: IniesRecord | MaterialRecord
  onSelected: (row: IniesRecord | MaterialRecord | undefined) => void
  getTypologyBackground: (typologie: DeclarationTypeEnum) => string
}

export default function MaterialRow({ record, onSelected, getTypologyBackground }: MaterialRowProps): React.JSX.Element {
  return (
    <TableRow
      onClick={() => {
        onSelected(record)
      }}
      hover
      sx={{ cursor: "pointer" }}>
      <TableCell align="left" component="th" scope="row">
        <Typography
          component="span"
          sx={{
            backgroundColor: getTypologyBackground(record.declarationType),
            color: customPalette.default,
            padding: "8px",
            borderRadius: "28px",
            fontSize: 12,
            whiteSpace: "nowrap",
          }}>
          {getDeclarationTypeLabel(record.declarationType)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Box>
          {record.fdesName}

          {record instanceof MaterialRecord && (
            <Typography
              component="span"
              sx={{
                fontWeight: "bold",

                overflow: "hidden",
                textOverflow: "ellipsis",
                color: record instanceof MaterialRecord ? originMaterialRecordToLabel(record).color : "",
              }}>
              {` (${record instanceof MaterialRecord ? originMaterialRecordToLabel(record).text : ""})`}
            </Typography>
          )}
        </Box>
      </TableCell>
      <TableCell align="left">
        {!(record instanceof IniesRecord) && (record?.provider || record?.productReference) && (
          <>
            {record?.provider && <Typography>{record?.provider?.toUpperCase()}</Typography>}
            {record?.productReference && <Typography sx={{ fontSize: 12 }}>{`${record?.productReference}`}</Typography>}
          </>
        )}
      </TableCell>
    </TableRow>
  )
}
