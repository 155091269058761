import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Children } from "../../../../components/miscellianous/children"
import { BSVariant } from "../../../dto/beem-shot/BSVariant/BSVariant"
import { BSVariantCreationDto } from "../../../dto/beem-shot/BSVariant/BSVariantCreateDto"
import { BSVariantUpdateDto } from "../../../dto/beem-shot/BSVariant/BSVariantUpdateDto"
import { CalculStatusEnum } from "../../../enum/calculStatusEnum"
import { useBSProject } from "../../../hooks/beem-shot/useBSProject"
import { useBSVariant } from "../../../hooks/beem-shot/useBSVariant"

export const BSVariantContext = React.createContext<BSVariantStore>({} as BSVariantStore)

export function BSVariantContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProjectId, bsVariantId } = useParams()
  const { createBSVariant, getBSVariants, updateBSVariant } = useBSVariant()

  const [bsVariants, setBsVariants] = useState<BSVariant[]>([])
  const [selectedVariant, setSelectedVariant] = useState<BSVariant>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { updateBSCalculStatus } = useBSProject()

  const fetchAllBsVariants = useCallback(
    (projectId: string): Promise<BSVariant[]> => {
      setIsLoading(true)
      return getBSVariants(projectId)
        .then((variants) => {
          setBsVariants(variants)
          return variants
        })

        .finally(() => setIsLoading(false))
    },
    [bsVariantId, getBSVariants]
  )

  const updateCalculStatus = useCallback(
    (status: CalculStatusEnum) => {
      if (bsVariantId && bsProjectId !== "new") {
        return updateBSCalculStatus(bsVariantId, status).then(() => {
          if (bsProjectId) {
            fetchAllBsVariants(bsProjectId)
          }
        })
      }
      return Promise.resolve()
    },
    [bsProjectId, bsVariantId, fetchAllBsVariants, updateBSCalculStatus]
  )

  const updateVariant = useCallback(
    (bsVariantUpdateDto: BSVariantUpdateDto) => {
      if (bsProjectId && bsProjectId !== "new") {
        return updateBSVariant(bsVariantUpdateDto).then((updatedBsVariant) => {
          setSelectedVariant(updatedBsVariant)
          setBsVariants((prevState) => prevState.map((item) => (item.id === updatedBsVariant.id ? updatedBsVariant : item)))
          return updatedBsVariant
        })
      }
      return Promise.resolve()
    },
    [bsProjectId, updateBSVariant]
  )

  useEffect(() => {
    if (bsProjectId) {
      fetchAllBsVariants(bsProjectId)
    }
  }, [bsProjectId, fetchAllBsVariants, bsVariantId])

  useEffect(() => {
    if (bsVariantId) {
      const variantFound = bsVariants.find((x) => x.id === bsVariantId)
      if (variantFound) {
        setSelectedVariant(variantFound)
      }
    }
  }, [bsVariantId, bsVariants])

  const createVariant = useCallback(
    (bsVariantCreationDto: BSVariantCreationDto, sourceVariantId: string | undefined) =>
      createBSVariant(bsVariantCreationDto, sourceVariantId).then((newBsVariant) => {
        setSelectedVariant(newBsVariant)
        return newBsVariant
      }),
    [createBSVariant]
  )

  const bsVariantStore = useMemo(
    () => ({
      bsVariants,
      selectedVariant,
      setSelectedVariant,
      createVariant,
      fetchAllBsVariants,
      updateVariant,
      setBsVariants,
      updateCalculStatus,
    }),
    [bsVariants, createVariant, fetchAllBsVariants, selectedVariant, updateCalculStatus, updateVariant]
  )

  return <BSVariantContext.Provider value={bsVariantStore}>{children}</BSVariantContext.Provider>
}

export type BSVariantStore = {
  bsVariants: BSVariant[]
  setBsVariants: React.Dispatch<React.SetStateAction<BSVariant[]>>
  selectedVariant: BSVariant | undefined
  setSelectedVariant: React.Dispatch<React.SetStateAction<BSVariant | undefined>>
  createVariant(bsVariantCreationDto: BSVariantCreationDto, sourceVariantId: string | undefined): Promise<BSVariant>
  fetchAllBsVariants(projectId: string): Promise<BSVariant[]>
  updateVariant(bsVariantCreationDto: BSVariantCreationDto): Promise<BSVariant> | Promise<void>
  updateCalculStatus(status: CalculStatusEnum): Promise<BSVariant | void>
}
