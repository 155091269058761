import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import React, { useEffect, useState } from "react"

interface IProps {
  id: string
  label: string
  value: boolean
  labelTrue?: string
  labelFalse?: string
  disabled?: boolean

  handleChange(event: React.ChangeEvent<HTMLInputElement>): void

  handleFocusOut?(): void
}

export function BooleanRadioButtonInput({
  id,
  label,
  value,
  labelTrue = "Oui",
  labelFalse = "Non",
  handleChange,
  handleFocusOut,
  disabled,
}: Readonly<IProps>): React.JSX.Element {
  const [isInitialized, setIsInitialized] = useState(false)

  function internalHandleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const customEvent = { ...event }
    customEvent.target.id = id
    customEvent.target.type = "checkbox"
    customEvent.target.checked = event.target.value === "true"

    handleChange(customEvent)
  }

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setIsInitialized(true)
    }
  }, [value])

  useEffect(() => {
    if (handleFocusOut && isInitialized) {
      handleFocusOut()
    }
  }, [value])

  return (
    <FormControl
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}>
      <FormLabel
        id={`${id}-label`}
        sx={{
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "0.15000000596046448px",
          textAlign: "left",
        }}>
        {label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby={`${id}-radio-buttons-group`}
        name="radio-buttons-group"
        value={`${value}`}
        onChange={internalHandleChange}>
        <FormControlLabel value="true" control={<Radio />} label={labelTrue} disabled={disabled} />
        <FormControlLabel value="false" control={<Radio />} label={labelFalse} disabled={disabled} />
      </RadioGroup>
    </FormControl>
  )
}
