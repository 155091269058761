import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import { Box, Grid } from "@mui/material"
import React from "react"
import { formatToFrench } from "../../../core/services/date-service"
import { IconFileAnalytics } from "../../custom-icons/icon-file-analytics"
import { DefaultTypography } from "../../typography/default-typography"

type IProps = {
  organizationBusinessName: string
  lastModifiedDate: Date
  numberDocument: string
  teamNumber: string
}

export function InfoCard({
  organizationBusinessName,
  lastModifiedDate,
  numberDocument,
  teamNumber,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Grid container display="flex" justifyContent="space-between" width="100%" alignItems="center">
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <HomeWorkOutlinedIcon />
          <Box sx={{ pl: 1 }}>
            <DefaultTypography label={organizationBusinessName || ""} fontSize="12px" lineHeight="18px" fontWeight={400} />
          </Box>
        </Box>

        <DefaultTypography
          label={`Mise à jour le ${formatToFrench(lastModifiedDate)}`}
          fontSize="12px"
          lineHeight="18px"
          fontWeight={400}
          color="#8398A6"
        />
      </Box>

      <Box display="flex" flexDirection="row" gap={2}>
        <Box display="flex" alignItems="center" gap={1} sx={{ background: "#5376BD", px: 1, py: 0.5, borderRadius: 2 }}>
          <IconFileAnalytics style={{ color: "#ffffff" }} />
          <DefaultTypography
            label={numberDocument ?? "0"}
            fontSize="14px"
            lineHeight="20.02px"
            fontWeight={400}
            color="#ffffff"
          />
        </Box>

        <Box display="flex" alignItems="center" gap={1} sx={{ background: "#000", px: 1, py: 0.5, borderRadius: 2 }}>
          <PersonOutlinedIcon fontSize="small" sx={{ color: "#fff" }} />
          <DefaultTypography
            label={teamNumber ?? "1"}
            fontSize="14px"
            lineHeight="20.02px"
            fontWeight={400}
            color="#ffffff"
          />
        </Box>
      </Box>
    </Grid>
  )
}
