import { DeclarationTypeEnum } from "../enum/declarationTypeEnum"
import { IniesRecord } from "../dto/material/IniesRecord"
import { MaterialRecord } from "../dto/material/MaterialRecord"
import { FicheConfiguree } from "../dto/fiche-configuree/fiche-configuree"

const declarationTypeLabel = {
  DONNEE_PAR_DEFAUT: "Donnée par défaut",
  COLLECTIVE: "Collective",
  INDIVIDUELLE: "Individuelle",
  FORFAITAIRE: "Conventionnelle", // Conventionnelle
  EMPTY: "Vide",
  REUSE: "Réutilisation",
  FICHE_CONFIGUREE: "Fiche configurée",
  UNKNOWN: "INCONNUE",
}
export const getDeclarationTypeLabel = (
  declarationTypeKey: keyof typeof declarationTypeLabel | string | undefined
): string => {
  if (declarationTypeKey && declarationTypeKey in declarationTypeLabel) {
    return declarationTypeLabel[declarationTypeKey as keyof typeof declarationTypeLabel]
  } else if (declarationTypeKey === undefined) {
    return ""
  } else {
    return declarationTypeKey
  }
}

export const getDeclarationTypeBackground = (declarationType: DeclarationTypeEnum | undefined): string => {
  switch (declarationType) {
    case DeclarationTypeEnum.COLLECTIVE:
      return "#D2691E"
    case DeclarationTypeEnum.FORFAITAIRE:
      return "#20B2AA"
    case DeclarationTypeEnum.INDIVIDUELLE:
      return "#FF7F50"
    case DeclarationTypeEnum.FICHE_CONFIGUREE:
      return "#92D050"
    default:
      return "#4169E1"
  }
}

export function getRecordId(record: IniesRecord | MaterialRecord | FicheConfiguree | undefined): string {
  if (!record) return ""

  if (record instanceof FicheConfiguree) {
    return record.epdcId
  }

  if ("iniesId" in record) {
    return `${record.iniesId}`
  }

  return ""
}
