import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined"
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined"
import { Box } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { pagesUrl } from "../../../core/appConstants"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { BSVariantList } from "../BSVariantDetailsPage/components/BSVariantList"
import { CustomTabPanel, TabPanelProps } from "../components/CustomTabPanel"

export function BSProjectDetailsPage(): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)
  const { bsVariants } = useContext(BSVariantContext)
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle, setPagination } =
    useContext(AppNavBarContext)

  const location = useLocation()

  const [tabs, setTabs] = React.useState("BS_VARIANTS")

  useEffect(() => {
    const defaultTab = location.state?.defaultTab
    if (typeof defaultTab === "string") {
      setTabs(defaultTab)
    }
    setPreviousUrl(pagesUrl.BEEM_SHOT_HOME)
    setShowProjectStatus(true)
    setTitle(bsProject?.name ?? "")
    setPagination(["Tableau de bord", "Variantes"])

    return () => {
      setShowProjectStatus(false)
      setTitle("BEEM SHOT")
    }
  }, [
    bsProject?.name,
    location,
    setPagination,
    setPreviousUrl,
    setShowProjectStatus,
    setStepHeader,
    setTitle,
    setTypeNavBar,
  ])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide])

  function handleChange(event: React.SyntheticEvent, newValue: string): void {
    setTabs(newValue)
  }

  const tabTest: TabPanelProps[] = [
    {
      id: "BS_VARIANTS",
      Icon: FileCopyOutlinedIcon,
      label: "Variantes",
      chips: bsVariants.length.toString(),
      content: <BSVariantList />,
    },
    {
      id: "BS_BIM_MODEL_POOL",
      Icon: FolderCopyOutlinedIcon,
      label: "Maquettes BIM",
      chips: undefined,
      content: <Box />,
      disabled: true,
      disabledText: "Fonctionnalité à venir",
    },
  ]

  return (
    <Box display="flex" flexDirection="column" height="78vh">
      <CustomTabPanel panels={tabTest} tabs={tabs} handleChange={handleChange} />
    </Box>
  )
}
