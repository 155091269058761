import { Box, Grid } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { pagesUrl } from "../../../core/appConstants"
import { RseeProjectCardDto } from "../../../core/dto/rsee/rsee-project-card"
import { resolveUrl } from "../../../core/services/http-service"
import IconFolder from "../../custom-icons/icon-folder"
import { DefaultTypography } from "../../typography/default-typography"
import { ProjectCardStatus } from "../beem-master/project-status-card"
import { InfoCard } from "../components/infoCard"

type IProps = {
  rseeProjectCard: RseeProjectCardDto
}

export function RseeProjectCard({ rseeProjectCard }: Readonly<IProps>): React.JSX.Element {
  function getNavigateUrl(): string {
    return resolveUrl(pagesUrl.BEEM_PILOT_PROJECT, [rseeProjectCard.projectId])
  }

  return (
    <Link to={getNavigateUrl()} style={{ width: "100%", minHeight: 100, textDecoration: "none", color: "inherit" }}>
      <Grid
        container
        display="flex"
        alignItems="center"
        flexDirection="column"
        rowGap={1}
        width="100%"
        sx={{
          backgroundColor: "#fff",
          borderRadius: 3,
          p: 3,
          boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
        }}>
        <Grid container item display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Grid item xs={1}>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              borderRadius={2}
              p={1}
              px={3}
              sx={{ background: "#E6F0FB" }}>
              <IconFolder style={{ fontSize: "30px" }} />
            </Box>
          </Grid>
          <Grid display="flex" justifyContent="flex-end" item xs={3}>
            <ProjectCardStatus status={rseeProjectCard.projectStatus} />
          </Grid>
        </Grid>

        <Grid container display="flex" justifyContent="left" alignItems="center" width="100%">
          <DefaultTypography label={rseeProjectCard.projectName} fontSize="16px" lineHeight="24px" fontWeight={600} />
        </Grid>

        <Grid container flexGrow={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 1 }}>
            <DefaultTypography label="N° Affaire" fontSize="11px" lineHeight="26.5px" fontWeight={400} color="#8398A6" />
            <DefaultTypography
              label={rseeProjectCard.businessCode || ""}
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
            />
          </Grid>
        </Grid>

        <InfoCard
          organizationBusinessName={rseeProjectCard.projectOrganizationBusinessName}
          lastModifiedDate={rseeProjectCard.lastModifiedDate}
          numberDocument={rseeProjectCard.numberDocument.toString()}
          teamNumber={rseeProjectCard.teamNumber}
        />
      </Grid>
    </Link>
  )
}
