import { Button, CircularProgress, createTheme, Grid, Link, ThemeProvider, Typography } from "@mui/material"
import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { GoogleReCaptchaContext, IGoogleReCaptchaStore } from "../../../../components/captcha/google-recaptcha-context"
import LogoTTBTransparent from "../../../../components/custom-icons/simple-logo"
import { pagesUrl } from "../../../../core/appConstants"
import { DomainNameValidatorContext } from "../../../../core/context/organization/domain-name-validator-context"
import { TestUserCreationDto } from "../../../../core/dto/user/test-user-creation-dto"
import { useUser } from "../../../../core/hooks/use-user"
import { resolveUrl } from "../../../../core/services/http-service"
import { isEmailValid, isPasswordValid, isPhoneValid } from "../../../../core/services/user-service"
import { CaptchaActions, SUBMIT_ACTION, TestUserFormType } from "./captcha-field-utils"
import FormField from "./form-field"
import PasswordField from "./password-field"
import PhoneField from "./phone-field"

const greenLogo = "#0BE29F"

const textStyle1 = {
  fontWeight: "bold",
  fontSize: "2rem",
  textAlign: "center",
  color: "white",
}

const textStyle2 = {
  textAlign: "center",
  color: "white",
}

const theme = createTheme({
  palette: {
    primary: {
      main: greenLogo,
    },
  },
})

function formToDto(userForm: TestUserFormType): TestUserCreationDto {
  return {
    givenName: userForm.givenName.trim(),
    familyName: userForm.familyName.trim(),
    email: userForm.email.trim().toLowerCase(),
    organizationName: userForm.organizationName.trim(),
    phone: userForm.phone.trim(),
    password: userForm.password.trim(),
    captchaToken: "", // It will be filled later
  }
}

export default function TestUserForm(): React.JSX.Element {
  const navigate = useNavigate()
  const { createTestUser } = useUser()
  const { executeRecaptcha } = useContext<IGoogleReCaptchaStore>(GoogleReCaptchaContext)
  const { isDomainNameInvalid } = useContext(DomainNameValidatorContext)
  const [userForm, setUserForm] = useState<TestUserFormType>({
    givenName: "",
    familyName: "",
    organizationName: "",
    phone: "",
    email: "",
    password: "",
  })
  const [token, setToken] = useState("")
  const [noOfVerifications, setNoOfVerifications] = useState(0)
  const [dynamicAction, setDynamicAction] = useState<CaptchaActions>("unknown")
  const [error, setError] = useState<Record<string, any>>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available")
      return
    }
    const newToken = await executeRecaptcha("testUserForm")
    setToken(newToken)
    setNoOfVerifications(noOfVerifications + 1)
  }, [executeRecaptcha])

  useEffect(() => {
    if (!executeRecaptcha || !dynamicAction) {
      return
    }

    handleReCaptchaVerify()
  }, [handleReCaptchaVerify, dynamicAction])

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const id: CaptchaActions = target.id as CaptchaActions
    const value = target.type === "checkbox" ? target.checked : target.value

    setError({})

    setDynamicAction(id)
    setUserForm({ ...userForm, [id]: value })
  }

  function validate(userDto: TestUserFormType): boolean {
    const newError: Record<string, any> = {}
    let isValid = true

    if (!userDto.givenName) {
      newError.givenName = "Le prénom doit être renseigné"
      isValid = false
    }

    if (!userDto.familyName) {
      newError.familyName = "Le nom doit être renseigné"
      isValid = false
    }

    if (!userDto.organizationName) {
      newError.organizationName = "L'entreprise doit être renseigné"
      isValid = false
    }

    if (!userDto.phone) {
      newError.phone = "Le numéro de téléphone doit être renseigné"
      isValid = false
    } else if (!isPhoneValid(userDto.phone)) {
      newError.phone =
        'Le numéro de téléphone doit être composé uniquement de chiffres et faire 10 caractères ou commencer par "+" et faire 12 caractères'
      isValid = false
    }

    if (!userDto.email) {
      newError.email = "L'email doit être renseigné"
      isValid = false
    } else if (!isEmailValid(userDto.email)) {
      newError.email = "L'adresse email ne respecte pas la forme classique : email@complement.fin"
      isValid = false
    } else if (isDomainNameInvalid(userDto.email)) {
      newError.email = "Time To Beem est un service B2B. Merci d'utiliser une adresse email professionnelle"
      isValid = false
    }

    if (!userDto.password) {
      newError.password = "Le mot de passe doit être renseigné"
      isValid = false
    } else if (!isPasswordValid(userForm.password)) {
      newError.password = true
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (!executeRecaptcha) {
      return
    }

    const captchaAction: CaptchaActions = SUBMIT_ACTION
    const userDto = formToDto(userForm)
    if (validate(userDto)) {
      setIsSubmitting(true)
      executeRecaptcha(captchaAction)
        .then((newToken: string) => {
          setNoOfVerifications(noOfVerifications + 1)
          userDto.captchaToken = newToken
        })
        .then(() => createTestUser(userDto))
        .then(() => {
          setIsSubmitting(false)
          navigate(resolveUrl(pagesUrl.CONFIRM_PAGE, [], { email: userForm.email }))
          return Promise.resolve(undefined)
        })
        .catch((err: Error) => {
          setIsSubmitting(false)
          throw err
        })
    }
  }

  return (
    <Grid container component="form" onSubmit={handleSubmit} rowGap={2} sx={{ maxWidth: "600px", pl: 3, pr: 3 }}>
      <Grid item xs={12} display="flex" justifyContent="center">
        <LogoTTBTransparent style={{ height: "12rem", width: "12rem", padding: "1rem" }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" sx={textStyle1}>
          Créez un compte
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={textStyle2}>
          Vous avez déjà un compte ?{" "}
          <Link component={RouterLink} to={pagesUrl.SIGN_IN_PAGE} sx={{ textDecorationColor: "white", color: "white" }}>
            Connexion
          </Link>
        </Typography>
      </Grid>

      <Grid item xs={6} display="flex" flexDirection="column">
        <FormField error={error} form={userForm} fieldName="givenName" label="Prénom" handleChange={handleChange} required />
      </Grid>
      <Grid item xs={6} display="flex" flexDirection="column">
        <FormField error={error} form={userForm} fieldName="familyName" label="Nom" handleChange={handleChange} required />
      </Grid>
      <Grid item xs={6} display="flex" flexDirection="column">
        <FormField
          error={error}
          form={userForm}
          fieldName="organizationName"
          label="Entreprise"
          handleChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={6} display="flex" flexDirection="column">
        <PhoneField form={userForm} error={error} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} display="flex" flexDirection="column">
        <FormField error={error} form={userForm} fieldName="email" label="Email" handleChange={handleChange} required />
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column">
        <PasswordField
          error={error}
          form={userForm}
          fieldName="password"
          label="Mot de passe"
          handleChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={12} />
      <Grid item sx={{ display: "flex", justifyContent: "center", mb: "2rem" }} xs={12}>
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <ThemeProvider theme={theme}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                maxHeight: "3rem",
                minWidth: "70%",
                color: "#FFFFFF",
              }}>
              Envoyer
            </Button>
          </ThemeProvider>
        )}
      </Grid>
    </Grid>
  )
}
