import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react"
import CancelButton from "../../../../components/buttons/cancel-button/cancel-button"
import { ValidateButton } from "../../../../components/buttons/validate-button/validate-button"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import {
  BSProjectContext,
  dtoToForm,
  formToUpdateDto,
  IForm,
} from "../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { ProjectStatusEnum, projectStatusEnumLabels } from "../../../../core/enum/projectStatusEnum"
import { useForm } from "../../../../core/hooks/form/use-form"
import { enumToSelectOptions } from "../../../../core/services/helper-service"
import { SelectInput } from "../../../../components/inputs/select-input/SelectInput"

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function UpdateBSProjectDialog({ open, setOpen }: Readonly<IProps>): React.JSX.Element {
  const { bsProject, updateProject } = useContext(BSProjectContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  function onClose(): void {
    resetForm()
    setOpen(false)
  }

  const submit: (form: IForm) => Promise<any> = useCallback(
    (form: IForm) => {
      if (bsProject?.id) {
        setIsSubmitting(true)
        return updateProject(formToUpdateDto(bsProject, form))
          .then(() => {
            onClose()
          })
          .finally(() => setIsSubmitting(false))
      }
      return Promise.resolve()
    },
    // onClose
    [bsProject, updateProject]
  )

  const { form, handleChange, resetForm } = useForm(bsProject, dtoToForm, [], submit)

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="md">
      <DialogTitle>{`Mise à jour du status de "${bsProject?.name}"`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="updateBsProjectStatus">
          Modifiez l'état de votre projet selon son avancement. Veuillez noter qu'en passant au statut "Archivé", l'accès
          sera limité à la lecture seule, et aucune modification ne sera possible.
          <SelectInput
            id="status"
            label="Statut"
            selectedOption={form.status}
            options={enumToSelectOptions(ProjectStatusEnum, projectStatusEnumLabels).filter(
              (selectedOption) =>
                selectedOption.value !== ProjectStatusEnum.DELETED && selectedOption.value !== ProjectStatusEnum.ALL
            )}
            mode="event"
            handleEventChange={handleChange}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton action={() => onClose()} label="Annuler" />
        <ValidateButton
          action={() => {
            if (bsProject) {
              updateProject(formToUpdateDto(bsProject, form))
                .then(() => {
                  onClose()
                  openSuccessSnackbar("Le statut du projet a bien été modifié")
                })
                .finally(() => {
                  onClose()
                })
            }
          }}
          label="Valider"
        />
      </DialogActions>
    </Dialog>
  )
}
