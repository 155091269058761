import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSBimModel } from "../../dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelCreateOrUpdate } from "../../dto/beem-shot/BSBimModel/BSBimModelCreateOrUpdate"
import { BSProjectInvitation } from "../../dto/beem-shot/BSBimModel/BSProjectInvitation"
import { BSProjectInvitationCreationDto } from "../../dto/beem-shot/BSBimModel/BSProjectInvitationCreationDto"
import { streamToBlob } from "../../services/file-service"
import { resolveUrl } from "../../services/http-service"
import { useHttp } from "../use-http"

type BimModelHook = {
  sendBsBimModel(bsModelForm: BSBimModelCreateOrUpdate, bsVariantId: string): Promise<BSBimModel>
  sendBsFile(bsbimModelFile: File, bsbimModelId: string): Promise<Response>
  fetchBSBimModelFile(bsVariantId: string): Promise<Blob | undefined>
  fetchBimModelByVariantId(bsVariantId: string): Promise<BSBimModel | undefined>
  sendInvitation(
    bsProjectInvitationCreationDto: BSProjectInvitationCreationDto,
    bsVariantId: string
  ): Promise<BSProjectInvitation>
}

export function useBSBimModel(): BimModelHook {
  const { get, post, postFile } = useHttp()

  return useMemo(
    () => ({
      sendBsBimModel(bsModelForm: BSBimModelCreateOrUpdate, bsVariantId: string): Promise<BSBimModel> {
        return post(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL, bsModelForm, [
          {
            key: "bsVariantId",
            value: bsVariantId,
          },
        ])
          .then((r) => r.json())
          .then((data) => BSBimModel.fromDto(data))
      },
      sendBsFile(bsbimModelFile: File, bsbimModelId: string): Promise<Response> {
        const formData = new FormData()
        formData.append("ifcFile", bsbimModelFile)
        formData.append("bsbimModelId", bsbimModelId)
        return postFile(`${appConstants.apiEndpoints.BEEM_SHOT}/file`, formData)
      },
      fetchBSBimModelFile(bsVariantId): Promise<Blob | undefined> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_FILE, [bsVariantId]))
          .then((res) => streamToBlob(res))
          .catch(() => undefined)
      },
      fetchBimModelByVariantId(bsVariantId: string): Promise<BSBimModel | undefined> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_ID, [bsVariantId]))
          .then((r) => r.json())
          .then((data: any) => BSBimModel.fromDto(data))
          .catch(() => undefined)
      },
      sendInvitation(
        bsProjectInvitationCreationDto: BSProjectInvitationCreationDto,
        bsVariantId: string
      ): Promise<BSProjectInvitation> {
        return post(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_INVITATION, [], { bsVariantId }),
          bsProjectInvitationCreationDto,
          []
        )
          .then((r) => r.json())
          .then((invitation: BSProjectInvitation) => invitation)
      },
      sendBackInvitation(bsProjectId: string, bsInvitationId: string): Promise<void> {
        return post(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_SEND_BACK_INVITATION, [bsProjectId]), {}, [
          {
            key: "bsInvitationId",
            value: bsInvitationId,
          },
        ]).then()
      },
      cancelInvitation(bsInvitationId: string): Promise<void> {
        return post(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_CANCEL_INVITATION, [bsInvitationId]),
          {},
          []
        ).then()
      },
    }),
    [get, post, postFile]
  )
}
