import { Box, Pagination } from "@mui/material"
import React from "react"

interface IProps {
  count: number
  page: number

  handlePagination(event: React.ChangeEvent<unknown>, newPage: number): void
}

export function CustomPagination({ count, page, handlePagination }: Readonly<IProps>): React.JSX.Element {
  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="right"
      alignItems="flex-end"
      alignContent="flex-end"
      alignSelf="flex-end">
      <Pagination count={count} page={page} onChange={handlePagination} color="primary" />
    </Box>
  )
}
