import DeleteIcon from "@mui/icons-material/Delete"
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material"
import React, { useContext, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { DeleteDialog } from "../../../../components/dialog/delete-dialog"
import { adminPagesUrl, pagesUrl } from "../../../../core/appConstants"
import { UserContext } from "../../../../core/context/user/user-context"
import { RseeDocument } from "../../../../core/dto/rsee/rsee-document"
import { OrganizationRoleEnum } from "../../../../core/enum/user/organization-role-enum"
import { formatToFrench } from "../../../../core/services/date-service"
import { getEnvProperty, PROPERTIES } from "../../../../core/services/environment-service"
import { resolveUrl } from "../../../../core/services/http-service"

const envProperty: string = getEnvProperty(PROPERTIES.REACT_APP_FEATURE_DELETE_RSEE) // Feature flipping pour le RSEE, à enlever quand la fonctionnalité sera déployée

type IProps = {
  rseeDocument: RseeDocument
  deleteDocument(documentId: string): void
  isWriting: boolean
  updateCurrentDocument(documentId: string, toUpdate: boolean): void
}

export default function RseeDocumentCard({
  rseeDocument,
  deleteDocument,
  isWriting,
  updateCurrentDocument,
}: IProps): React.JSX.Element {
  const { organizationId } = useParams()
  const { user } = useContext(UserContext)
  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] = useState<boolean>(false)

  function handleEditDeleteDocumentDialog(): void {
    setOpenDeleteDocumentDialog(true)
  }

  function handleClose(): void {
    setOpenDeleteDocumentDialog(false)
  }

  return (
    <>
      <Card>
        <CardActionArea
          component={Link}
          to={
            user?.role === OrganizationRoleEnum.ULTIMATE_USER
              ? resolveUrl(adminPagesUrl.RSEE_PROJECT_DOCUMENT, [organizationId, rseeDocument.projectId, rseeDocument.id])
              : resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT, [rseeDocument.projectId, rseeDocument.id])
          }>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CardHeader title={rseeDocument.documentName} />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              {isWriting && (
                <FormControlLabel
                  value="end"
                  control={
                    <Switch
                      checked={rseeDocument.currentDocument}
                      onClick={(e) => {
                        e.stopPropagation()
                        updateCurrentDocument(rseeDocument.id, !rseeDocument.currentDocument)
                      }}
                      color="success"
                    />
                  }
                  label=""
                  labelPlacement="end"
                />
              )}
            </CardActions>
          </Box>
          <CardContent>
            <Grid container columnSpacing={3}>
              <Grid item xs={6}>
                <Typography fontSize={14} fontWeight="500">
                  Phase:
                </Typography>
                <Typography color="#CFCFCF" fontSize={14}>
                  {rseeDocument.nomPhase}
                </Typography>
              </Grid>
              {rseeDocument.createdDate && (
                <Grid item xs={6}>
                  <Typography fontSize={14} fontWeight="500">
                    Date d'import:
                  </Typography>
                  <Typography color="#CFCFCF" fontSize={14}>
                    {formatToFrench(rseeDocument.createdDate)}
                  </Typography>
                </Grid>
              )}
              {rseeDocument.labelDateEtude && (
                <Grid item xs={6}>
                  <Typography fontSize={14} fontWeight="500">
                    Date de l'étude thermique:
                  </Typography>
                  <Typography color="#CFCFCF" fontSize={14}>
                    {rseeDocument.labelDateEtude}
                  </Typography>
                </Grid>
              )}
              {rseeDocument.lastModifiedDate && (
                <Grid item xs={6}>
                  <Typography fontSize={14} fontWeight="500">
                    Date de dernière modification:
                  </Typography>
                  <Typography color="#CFCFCF" fontSize={14}>
                    {formatToFrench(rseeDocument.lastModifiedDate)}
                  </Typography>
                </Grid>
              )}
              {rseeDocument.createdByUser && (
                <Grid item xs={6}>
                  <Typography fontSize={14} fontWeight="500">
                    Créé par:
                  </Typography>
                  <Typography color="#CFCFCF" fontSize={14}>
                    {rseeDocument.createdByUser}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          {isWriting && (
            <IconButton aria-label="delete" size="large" onClick={handleEditDeleteDocumentDialog}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          )}
        </CardActions>
      </Card>
      <DeleteDialog
        id="deleteRseeDocument"
        title="Suppression du document RSEE"
        contentText="Êtes vous sûr de vouloir supprimer ce document du projet RSEE ?"
        handleClose={handleClose}
        action={() => deleteDocument(rseeDocument.id)}
        open={openDeleteDocumentDialog}
      />
    </>
  )
}
