// prettier-ignore
// @ts-ignore: this library is pure javacript, no types
// eslint-disable-next-line import/no-unresolved
import * as t from 'https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js'
import React from "react"
import {
  DataTable,
  DataValue,
  FilterOptions,
  GetSummaryDataOptions,
  GetUnderlyingDataOptions,
  ITableauEventType,
  LogicalTable,
} from "./tableau-commons"
import { FilterType } from "./tableau-extensions-service"

export const tableau = t
export const TableauViz = t.TableauViz
export const FilterUpdateType = t.FilterUpdateType
export const TableauEventType: ITableauEventType = t.TableauEventType
export const SheetType = t.SheetType // {Dashboard: 'dashboard', Story: 'story', Worksheet: 'worksheet'}
export const Dashboard = t.Dashboard
export const FilterAddType = t.FilterAddType
export const FilterRemoveType = t.FilterRemoveType
export const Workbook = t.Workbook
export const SelectionUpdateType = t.SelectionUpdateType

interface WorkSheet {
  name: string

  addEventListener(type: ITableauEventType, filterChangedHandler: (filterEvent: any) => void): void

  applyFilterAsync(
    fieldName: string,
    values: string[],
    updateType: typeof FilterUpdateType,
    options?: FilterOptions
  ): Promise<string>

  getFiltersAsync(): Promise<Array<Filter>>

  clearFilterAsync(fieldName: string): Promise<string>

  getDataSourcesAsync(): Promise<any>

  getSummaryColumnsInfoAsync(): Promise<any>

  getSummaryDataAsync(options?: GetSummaryDataOptions): Promise<DataTable>

  getUnderlyingDataAsync(options?: GetUnderlyingDataOptions | undefined): Promise<DataTable>

  getUnderlyingTableDataAsync(tableId: string, options?: GetUnderlyingDataOptions): Promise<DataTable>

  getUnderlyingTablesAsync(options?: GetUnderlyingDataOptions): Promise<LogicalTable[]>
}

interface Filter {
  fieldId: string
  fieldName: string
  filterType: typeof FilterType
}

export enum FilterKey {
  VARIANTE_1_FILTER = "variantId (bsVariantResult)",
  DOCUMENT_NAME_FILTER = "Document Name",
}

// Types for tableau js lib
export interface TableauViz {
  addEventListener(type: ITableauEventType, fct: (event: any) => Promise<void>): void
}

export const tableauService = {
  /**
   * example: clickOnWorksheet(vizRef, 'test-param', 'Batiment Id')
   */
  clickOnWorksheet(vizRef: React.MutableRefObject<any>, worksheetName: string, fieldName: string): void {
    const targetWorksheet = vizRef.current.workbook?.activeSheet?.worksheets?.find((w: any) => w.name === worksheetName)
    targetWorksheet?.selectMarksByValueAsync([{ fieldName, value: [1] }], SelectionUpdateType.Replace)
  },
  getWorksheetByName(vizRef: React.MutableRefObject<any>, worksheetName: string): WorkSheet {
    return vizRef.current.workbook?.activeSheet?.worksheets?.find((w: WorkSheet) => w.name === worksheetName)
  },
  findAllFilters(vizRef: React.MutableRefObject<any>): any {
    const filtersInfo = []
    const workbook = vizRef.current.workbook
    const activeSheet = workbook.activeSheet
    if (activeSheet.worksheets) {
      // Get all worksheets in the dashboard
      const worksheets = activeSheet.worksheets
      for (const worksheet of worksheets) {
        worksheet.getFiltersAsync().then((filters: any) => {
          filters.forEach((filter: any, index: number) => {
            filtersInfo.push({
              worksheetName: worksheet.name,
              fieldName: filter.fieldName,
              filterType: filter.filterType,
              values: filter.appliedValues,
            })
          })
        })
      }
    } else {
      // If it's a single worksheet
      activeSheet.getFiltersAsync().then((filters: any) => {
        filters.forEach((filter: any) => {
          filtersInfo.push({
            worksheetName: activeSheet.name,
            fieldName: filter.fieldName,
            filterType: filter.filterType,
            values: filter.appliedValues,
          })
        })
      })
    }
  },
  addListenerToGetDataOfAChartWhenFiltered(vizRef: React.MutableRefObject<any>, chartName: string) {
    vizRef.current.addEventListener(TableauEventType.FilterChanged, async (event: any) => {
      if (event?.detail?.sheet?.name === chartName) {
        const worksheet = event.detail.sheet
        const options = {
          maxRows: 0, // 0 means no limit
          ignoreAliases: false,
          ignoreSelection: true,
          includeAllColumns: true,
        }

        worksheet.getSummaryDataAsync(options).then((summaryData: any) => {
          const data = summaryData.data.map((row: any) => {
            const rowData: any = {}
            summaryData.columns.forEach((col: any, index: number) => {
              rowData[col.fieldName] = row[index].formattedValue
            })
            return rowData
          })

          const codeOccListJson: string = JSON.stringify(
            data.map((element: { codeOccurrence: string }) => element.codeOccurrence)
          )

          console.info("codeOccListJson", codeOccListJson)
        })
      }
    })
  },
  async applyFilterOnActiveSheet(
    vizRef: React.MutableRefObject<any>,
    filterName: FilterKey,
    filterValue: string[]
  ): Promise<void> {
    tableauService.applyFilter(vizRef.current.workbook.activeSheet, filterName, filterValue)
  },
  async applyFilter(worksheet: WorkSheet, filterName: FilterKey, filterValue: string[]): Promise<void> {
    await worksheet.applyFilterAsync(filterName, filterValue, FilterUpdateType.Replace)
  },
  async applyFilterToWorksheet(
    vizRef: React.MutableRefObject<any>,
    worksheetName: string,
    filterName: FilterKey,
    filterValue: string[]
  ): Promise<void> {
    const worksheet: WorkSheet = tableauService.getWorksheetByName(vizRef, worksheetName)
    await tableauService.applyFilter(worksheet, filterName, filterValue)
  },
  async getWorksheetData(worksheet: WorkSheet | undefined, options?: GetSummaryDataOptions): Promise<string[] | null> {
    if (worksheet) {
      // Get the data
      const dataTable: DataTable = await worksheet.getSummaryDataAsync(options)
      console.debug("worksheet ", worksheet, "dataTable", dataTable)
      const data: string[] = []
      dataTable.data.forEach((dataValueArray: DataValue[]) => {
        if (Array.isArray(dataValueArray) && dataValueArray.length !== 0) {
          const dataValue = dataValueArray[0]
          if (dataValue.nativeValue) {
            data.push(dataValue.nativeValue)
          }
        } else {
          console.error("Attention, la valeur n'est pas bien récupérée")
          console.error("dataValueArray", dataValueArray)
        }
      })
      return data
    }
    return null
  },
  setWorksheetFilterFirstValue(vizRef: React.MutableRefObject<any>, worksheetName: string, filterName: string) {
    // This function is not working because getUnderlyingData don't get all the possible values
    const worksheet: WorkSheet = tableauService.getWorksheetByName(vizRef, worksheetName)
    console.info("worksheet", worksheet)
    tableauService.getSummaryDataAsync(worksheet).then((worksheetData) => {
      if (worksheetData && worksheetData.length > 0) {
        // Get the first document name
        const firstDocumentName = worksheetData[0]
        console.info("First Document:", firstDocumentName)

        // Get the worksheet by name
        if (worksheet) {
          // Set the filter on the worksheet using the "Filter [Document Name]" field
          worksheet
            .applyFilterAsync(filterName, [], FilterUpdateType.REPLACE)
            .then(() => {
              console.info(`Filter applied with first document name: ${firstDocumentName}`)
            })
            .catch((error) => {
              console.error("Error applying filter:", error)
            })
        } else {
          console.error(`Worksheet ${worksheetName} not found.`)
        }
      } else {
        console.error("No data available in worksheet.")
      }
    })
  },
  async getSummaryDataAsync(worksheet: WorkSheet): Promise<string[][]> {
    if (worksheet) {
      try {
        const response: any = await worksheet.getSummaryDataAsync()
        console.info("getSummaryDataAsync:", response)
        return response.data
      } catch (err) {
        console.error("Error fetching underlying data:", err)
      }
    }
    return []
  },
}
