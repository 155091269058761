import { useMemo } from "react"
import { appConstants } from "../appConstants"
import { FicheConfiguree } from "../dto/fiche-configuree/fiche-configuree"
import { useHttp } from "./use-http"

type MaterialHook = {
  sendFicheConfiguree: (ficheConfiguree: File) => Promise<FicheConfiguree>
  getFicheConfiguree: (limit: number, lastRecordId?: string, searchState?: string) => Promise<FicheConfiguree[]>
}

export function useFicheConfiguree(): MaterialHook {
  const { get, post, postFile } = useHttp()

  return useMemo(
    () => ({
      sendFicheConfiguree(ficheConfiguree: File): Promise<FicheConfiguree> {
        const formData = new FormData()
        formData.append("ficheConfiguree", ficheConfiguree)
        return postFile(`${appConstants.apiEndpoints.BEEM_SHOT}/fiche-configuree`, formData).then((response) =>
          response.json()
        )
      },
      getFicheConfiguree(limit: number, lastRecordId?: string, searchState?: string): Promise<FicheConfiguree[]> {
        let params = [
          {
            key: "limit",
            value: String(limit),
          },
        ]

        if (lastRecordId) {
          params = [
            ...params,
            {
              key: "lastRecordId",
              value: lastRecordId,
            },
          ]
        }

        if (searchState) {
          params = [
            ...params,
            {
              key: "searchState",
              value: searchState,
            },
          ]
        }

        return get(`${appConstants.apiEndpoints.BEEM_SHOT}/fiche-configuree`, params)
          .then((list) => list.json())
          .then((res: any[]) => {
            const ficheConfigureeList: FicheConfiguree[] = res.map((record) => FicheConfiguree.fromDto(record))
            return ficheConfigureeList
          })
      },
    }),
    [get, postFile]
  )
}
