import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import { customPalette } from "../../../../theme"
import { useUser } from "../../../core/hooks/use-user"
import { IndicatorCard } from "../indicator-card"

export function TestUserIndicatorProjectHome(): React.JSX.Element {
  const { getUserRseeMax, getUserRseeCount } = useUser()
  const [maxRseeProject, setMaxRseeProject] = useState<string>("")
  const [rseeCount, setRseeCount] = useState<string>("")

  useEffect(() => {
    getUserRseeMax().then((projectCount) => setMaxRseeProject(`${projectCount}`))
  }, [getUserRseeMax])

  useEffect(() => {
    getUserRseeCount().then((projectCount) => setRseeCount(`${projectCount}`))
  }, [getUserRseeMax])

  function getText(): string {
    return `${rseeCount}/${maxRseeProject}`
  }

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={3}>
        <IndicatorCard
          icone="list_alt"
          title="Projets RSEE utilisés"
          backgroundColor={customPalette.lightGreen6}
          text={getText()}
        />
      </Grid>
      <Grid item xs={3} />
    </Grid>
  )
}
