export enum altitudeEnum {
  MINUS_THEN_400_M = "MINUS_THEN_400_M",
  BETWEEN_400_800_M = "BETWEEN_400_800_M",
  MORE_THEN_800_M = "MORE_THEN_800_M",
}

export const altitudeEnumLabels = {
  [altitudeEnum.MINUS_THEN_400_M]: "< 400 m",
  [altitudeEnum.BETWEEN_400_800_M]: "400m - 800m",
  [altitudeEnum.MORE_THEN_800_M]: "> 800m",
}
