import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import React, { useEffect, useState } from "react"

// extends string to say yhat it should be an enum
interface EnumOption<T extends string> {
  label: string
  value: T
}

interface IProps<T extends string> {
  id: string
  label: string
  value: T
  options: EnumOption<T>[]
  flexDirection?: "row" | "column"
  alignItems?: "center" | "space-between" | "flex-start" | "flex-end"
  disabled?: boolean

  handleChange(event: React.ChangeEvent<HTMLInputElement>): void

  handleSubmit?(): void
}

export function EnumRadioButton<T extends string>({
  id,
  label,
  value,
  options,
  handleChange,
  handleSubmit,
  flexDirection = "row",
  alignItems = "center",
  disabled,
}: Readonly<IProps<T>>): React.JSX.Element {
  const [isInitialized, setIsInitialized] = useState(false)

  function internalHandleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    event.target.id = id
    handleChange(event)
  }

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setIsInitialized(true)
    }
  }, [value])

  useEffect(() => {
    if (handleSubmit && isInitialized) {
      handleSubmit()
    }
  }, [value])

  return (
    <FormControl
      disabled={disabled}
      sx={{
        display: "flex",
        flexDirection,
        alignItems,
        justifyContent: "space-between",
        width: "100%",
      }}>
      <FormLabel
        id={`${id}-label`}
        sx={{
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          textAlign: "left",
        }}>
        {label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby={`${id}-radio-buttons-group`}
        name="radio-buttons-group"
        value={`${value}`}
        onChange={internalHandleChange}>
        {options.map((option) => (
          <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
