/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Box, Link, Typography } from "@mui/material"
import React from "react"
import { getFullNameLabel } from "../../../core/services/helper-service"

type IProps = {
  cognitoUserId: string
  firstName: string | undefined
  lastName: string | undefined
  revokeAction(cognitoUserId: string, firstName: string | undefined, lastName: string | undefined): void
  colorAvatar: string
  revokeButton: boolean
}

export default function TeamDetail({
  cognitoUserId,
  firstName,
  lastName,
  revokeAction,
  colorAvatar,
  revokeButton,
}: IProps): React.JSX.Element {
  const initName = firstName === undefined ? null : firstName[0].toUpperCase()
  const fullName = getFullNameLabel(firstName, lastName)

  function onRevokeAction(): void {
    revokeAction(cognitoUserId, firstName, lastName)
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row", padding: "2% 5%" }}>
      <Avatar sx={{ bgcolor: colorAvatar, size: "large" }} variant="rounded">
        {initName}
      </Avatar>
      <Box sx={{ display: "block", padding: "0% 5%" }}>
        <Typography className="notranslate" component="div" sx={{ fontWeight: "bold" }}>
          {fullName}
        </Typography>
        <Typography component="div" sx={{ fontSize: "10px" }}>
          <Box>
            {revokeButton && (
              <Link onClick={onRevokeAction} sx={{ cursor: "pointer" }} underline="always">
                Révoquer les droits
              </Link>
            )}
          </Box>
        </Typography>
      </Box>
    </Box>
  )
}
