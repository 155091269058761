import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"
import { getModulTypeEnum, ModuleTypeEnum } from "../../../../core/enum/bimModel/ModuleTypeEnum"
import {
  AUTH_TOKEN_PARAMETER_NAME,
  DASHBOARD_TYPE_PARAMETER_NAME,
  tableauExt,
  tableauExtensionsService,
  tExtensions,
  WORKSHEET_FOR_BIM_MODEL_INTERACTION,
} from "../../../../core/services/tableau/tableau-extensions-service"
import { CodeExtraitDisplay } from "../../../../core/dto/code-extrait/CodeExtraitDisplay"
import { codeToKey } from "../../../../core/services/code-service"
import { TableauParameter } from "../../../../core/services/tableau/tableau-commons"

export const DashboardExtensionContext = React.createContext<DashboardExtensionStore>({} as DashboardExtensionStore)

export function BimModelExtensionContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [bimModelToken, setBimModelToken] = useState<string | undefined>(undefined)
  const [dashboardType, setDashboardType] = useState<ModuleTypeEnum>(ModuleTypeEnum.BEEM_MASTER)
  const [selectedData, setSelectedData] = useState<string[]>([])
  const filteringFromModelRef = useRef<boolean>(false)

  const filterChangedHandler = useCallback((): void => {
    tableauExtensionsService.getWorksheetData(WORKSHEET_FOR_BIM_MODEL_INTERACTION).then((data) => {
      if (data !== null) {
        setSelectedData(data)
      }
    })
  }, [])

  useEffect(() => {
    if (tExtensions) {
      tExtensions.initializeAsync().then(() => {
        tableauExtensionsService.fetchParameters(tExtensions).then((parameters: TableauParameter[]) => {
          parameters.forEach((parameter: TableauParameter) => {
            if (parameter.name === AUTH_TOKEN_PARAMETER_NAME && parameter?.currentValue?.value) {
              setBimModelToken(parameter.currentValue.value)
            }
            if (parameter.name === DASHBOARD_TYPE_PARAMETER_NAME) {
              setDashboardType(getModulTypeEnum(parameter?.currentValue?.value))
            }
          })
        })

        const codeOccurrenceWorksheet = tableauExtensionsService.getWorksheetByName(WORKSHEET_FOR_BIM_MODEL_INTERACTION)
        if (codeOccurrenceWorksheet) {
          codeOccurrenceWorksheet.addEventListener(tableauExt.TableauEventType.FilterChanged, filterChangedHandler)
        }
      })
    } else {
      console.error("Tableau extensions were not loaded correctly yet. tableauExtension: ", tableauExt)
    }
  }, [filterChangedHandler])

  /**
   * Marche presque. il faut utiliser selectMarksByValueAsync() au lieu de "applyFilterAsync('codeOccurrence', filteredValues, tableauExt.FilterUpdateType.Replace)"
   * Il faut aussi régler quelques petits détails. Revenir implémenter ça quand on aura le temps. Ne pas supprimer cette fonction
   */
  const setSelectedCodeExtrait1 = useCallback((code: CodeExtraitDisplay): void => {
    console.info("select code", code)
    const filteredValues = [codeToKey(code)]
    console.info("filteredValues", filteredValues)

    filteringFromModelRef.current = true
    tableauExtensionsService
      .clearAllActionFilters()
      .then(() => tableauExtensionsService.getWorksheetByName(WORKSHEET_FOR_BIM_MODEL_INTERACTION))
      .then((worksheet) => {
        if (worksheet) {
          console.info("tableauExt.FilterUpdateType", tableauExt.FilterUpdateType)
          console.info("applyFilterAsync", "codeOccurrence", filteredValues, tableauExt.FilterUpdateType.Replace)
          return worksheet
            .applyFilterAsync("codeOccurrence", filteredValues, tableauExt.FilterUpdateType.Replace)
            .then(() => undefined)
        } else {
          return Promise.resolve()
        }
      })
      .finally(() => {
        filteringFromModelRef.current = false
      })
  }, [])

  const dummySelectCode = useCallback((code: CodeExtraitDisplay): void => {
    console.info("Select code on model. Not implemented yet")
  }, [])

  const bimModelAuthTokenStore = useMemo(
    () => ({
      bimModelToken,
      dashboardType,
      selectedData,
      setSelectedCodeExtrait: dummySelectCode,
    }),
    [bimModelToken, dashboardType, selectedData, dummySelectCode]
  )
  return <DashboardExtensionContext.Provider value={bimModelAuthTokenStore}>{children}</DashboardExtensionContext.Provider>
}

export type DashboardExtensionStore = {
  bimModelToken: string | undefined
  dashboardType: ModuleTypeEnum
  selectedData: string[]
  setSelectedCodeExtrait(code: CodeExtraitDisplay): void
}
