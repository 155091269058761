import { CircularProgress, Grid, Link, Typography } from "@mui/material"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Link as RouterLink, useParams } from "react-router-dom"
import { pagesUrl } from "../../core/appConstants"
import { UserContext } from "../../core/context/user/user-context"
import { ProjectCardDto } from "../../core/dto/project/project-card-dto"
import { ProjectTypeEnum } from "../../core/enum/project/projectTypeEnum"
import { ProjectStatusEnum } from "../../core/enum/projectStatusEnum"
import { OrganizationRoleEnum } from "../../core/enum/user/organization-role-enum"
import { useProjects } from "../../core/hooks/projects/use-projects"
import { isUltimateUser } from "../../core/services/authentication-service"
import ProjectCard from "../card/beem-master/project-card"

export function sortProjectList(projectsListData: ProjectCardDto[]): ProjectCardDto[] {
  return projectsListData.sort((a: ProjectCardDto, b: ProjectCardDto) => {
    if (a?.projectName && b?.projectName) {
      return a.projectName.localeCompare(b.projectName)
    } else {
      return 1
    }
  })
}

type IProps = {
  currentProjectType: ProjectTypeEnum
  projectTypeUpToDate: boolean
  currentStatus: ProjectStatusEnum
}

export function ProjectCardList({
  currentProjectType,
  projectTypeUpToDate,
  currentStatus,
}: Readonly<IProps>): React.JSX.Element {
  const { fetchProjectCards } = useProjects()
  const { user } = useContext(UserContext)
  const lastProjectId = useRef<string>("")
  const lastProjectName = useRef<string>("")
  const [projectCards, setProjectCards] = useState<ProjectCardDto[]>([])
  const { organizationId } = useParams()
  const isLoadingRef = useRef(false)

  const fetchProjectCardsInfo = useCallback(
    (projectType: ProjectTypeEnum, projectStatus: ProjectStatusEnum) => {
      if (!isLoadingRef.current) {
        isLoadingRef.current = true
        if (currentStatus !== ProjectStatusEnum.DELETED) {
          fetchProjectCards(
            projectType,
            projectStatus,
            -1,
            lastProjectName.current,
            lastProjectId.current,
            organizationId,
            isUltimateUser(user)
          )
            .then((projectsListData: ProjectCardDto[]) => {
              if (projectsListData !== undefined) {
                const sortProjectsListData = sortProjectList(projectsListData)
                lastProjectName.current =
                  sortProjectsListData[sortProjectsListData.length - 1]?.projectName || lastProjectName.current
                lastProjectId.current =
                  sortProjectsListData[sortProjectsListData.length - 1]?.projectId || lastProjectId.current
                setProjectCards((previousData) => [...previousData, ...sortProjectsListData])
              }
            })
            .finally(() => {
              isLoadingRef.current = false
            })
        }
      }
    },
    [currentStatus, fetchProjectCards, organizationId, user]
  )

  const reset = useCallback(() => {
    setProjectCards([])
    lastProjectName.current = ""
    lastProjectId.current = ""
  }, [])

  useEffect(() => {
    if (projectTypeUpToDate) {
      reset()
      fetchProjectCardsInfo(currentProjectType, currentStatus)
    }
  }, [fetchProjectCardsInfo, currentStatus, reset, currentProjectType, projectTypeUpToDate])

  if (!projectTypeUpToDate) {
    return <CircularProgress />
  } else if (
    user?.role === OrganizationRoleEnum.TEST_USER &&
    projectCards.length === 0 &&
    currentProjectType === ProjectTypeEnum.BIM
  ) {
    return (
      <>
        <Typography>
          Vous n'avez pas de projets en cours et vous ne pouvez pas créer de projet BIM sans abonnement.&nbsp;
        </Typography>
        <Typography>
          <Link component={RouterLink} to={pagesUrl.ASK_DEMO} color="#0072e5" target="_blank" rel="noopener noreferrer">
            Cliquer ici pour vous abonner
          </Link>
        </Typography>
      </>
    )
  } else if (isLoadingRef.current) {
    return <CircularProgress sx={{ mt: 11, ml: 20 }} size={30} />
  } else if (
    !isLoadingRef.current &&
    projectCards.length === 0 &&
    currentProjectType === ProjectTypeEnum.BIM &&
    currentStatus === ProjectStatusEnum.IN_PROGRESS
  ) {
    return <Typography>Vous n'avez pas de projets BIM en cours</Typography>
  } else if (
    !isLoadingRef.current &&
    projectCards.length === 0 &&
    currentProjectType === ProjectTypeEnum.BIM_ORGANIZATION &&
    currentStatus === ProjectStatusEnum.IN_PROGRESS
  ) {
    return <Typography>Vous n'avez pas de projets BIM en cours dans cette organisation</Typography>
  } else if (
    !isLoadingRef.current &&
    projectCards.length === 0 &&
    currentProjectType === ProjectTypeEnum.BIM &&
    currentStatus === ProjectStatusEnum.FINISHED
  ) {
    return <Typography>Vous n'avez pas encore de projets BIM terminés</Typography>
  } else if (
    !isLoadingRef.current &&
    projectCards.length === 0 &&
    currentProjectType === ProjectTypeEnum.RSEE &&
    currentStatus === ProjectStatusEnum.IN_PROGRESS
  ) {
    return <Typography>Vous n'avez pas de projets RSEE en cours</Typography>
  } else if (
    !isLoadingRef.current &&
    projectCards.length === 0 &&
    currentProjectType === ProjectTypeEnum.RSEE &&
    currentStatus === ProjectStatusEnum.FINISHED
  ) {
    return <Typography>Vous n'avez pas de projets RSEE terminés</Typography>
  } else {
    return (
      <>
        {projectCards.map((projectItem: ProjectCardDto) => (
          <Grid item xs={12} md={4} lg={3} key={projectItem.projectId}>
            <ProjectCard
              key={projectItem.projectId}
              projectCardInfo={projectItem}
              hasCoverImage={
                currentProjectType !== ProjectTypeEnum.RSEE && currentProjectType !== ProjectTypeEnum.RSEE_ORGANIZATION
              }
            />
          </Grid>
        ))}
      </>
    )
  }
}
