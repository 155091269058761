import React, { useContext } from "react"
import { CircularProgress } from "@mui/material"
import IconFolder from "../../../../components/custom-icons/icon-folder"
import { IconRseeGlobalDashboard } from "../../../../components/custom-icons/icon-rsee-global-dashboard"
import { ProjectListEmpty } from "../../../../components/project-list-empty/projectListEmpty"
import { RseeProjectListContext } from "../../../../core/context/beem-pilot/rsee/RseeProjectListContext"
import { CustomTabPanel, TabPanelProps } from "../../../beem-shot/components/CustomTabPanel"
import OrganizationDashboardPage from "../../../organization-dashboard-page/OrganizationDashboardPage"
import { RseeProjectList } from "./RseeProjectList"

export function RseeProjectListContainer(): React.JSX.Element {
  const { isOneProjectCreated, projectCount, isInitialized, isLoading } = useContext(RseeProjectListContext)

  const [tabs, setTabs] = React.useState("RSEE_PROJECTS")

  function handleChange(event: React.SyntheticEvent, newValue: string): void {
    setTabs(newValue)
  }

  const panels: TabPanelProps[] = [
    {
      id: "PILOTAGE_GENERAL",
      Icon: IconRseeGlobalDashboard,
      label: "Pilotage général",
      chips: undefined,
      content: <OrganizationDashboardPage />,
    },
    {
      id: "RSEE_PROJECTS",
      Icon: IconFolder,
      label: "Projets",
      chips: projectCount?.toString(),
      content: <RseeProjectList />,
    },
  ]
  if (!isInitialized || isLoading) {
    return <CircularProgress />
  } else if (!isOneProjectCreated.current) {
    return <ProjectListEmpty />
  } else {
    return <CustomTabPanel panels={panels} tabs={tabs} handleChange={handleChange} />
  }
}
