import { SelectOption } from "../../components/inputs/select-input/SelectInput"
import { appConstants } from "../appConstants"

function stringIsNumber(value: any): boolean {
  return Number.isNaN(Number(value))
}

export function stringToNumber(value: string | undefined): number {
  if (!value) {
    return 0
  } else {
    const number = parseFloat(value)
    return isNumber(number) ? number : 0
  }
}

export function isFloat(value: string): boolean {
  const number = parseFloat(value)
  return !(Number.isNaN(number) || !appConstants.regex.number.test(value))
}

export function displayNumber(number: number | undefined): string {
  if (!number || !isNumber(number)) {
    return "0"
  } else {
    return number.toFixed(0)
  }
}

export function isNumber(value: number | undefined): boolean {
  return typeof value === "number" && Number.isFinite(value)
}

export function displayDecimal(number: number | undefined, decimalNumber = 2): string {
  if (!number || Number.isNaN(number)) {
    return "0"
  } else {
    return number.toFixed(decimalNumber)
  }
}

const collator = new Intl.Collator("fr", {
  usage: "search",
  sensitivity: "base",
  ignorePunctuation: true,
})

// Turn enum into array
export function enumToArray(enumme: any): any[] {
  // It is for an enum containing numerical values
  return Object.keys(enumme)
    .filter(stringIsNumber)
    .map((key) => enumme[key])
}

export function stringEnumToArray<T extends Record<string, string>>(enumType: T): string[] {
  // return Object.entries(psetPropertyFileEnum).map(([, value]) => value)
  const list: string[] = []
  for (const key in enumType) {
    if (Object.hasOwn(enumType, key)) {
      list.push(enumType[key])
    }
  }
  return list
}

export function enumToSelectOptions<T extends Record<string, any>>(
  enumObj: T,
  labelMap: { [key in keyof T]: string }
): SelectOption<T[keyof T]>[] {
  return Object.entries(enumObj).map(([key, value]) => ({
    label: labelMap[key],
    value: value as T[keyof T],
  }))
}

export function enumArrayToSelectOptions<T extends string>(enumObj: T[], labelMap: Record<T, string>): SelectOption<T>[] {
  return enumObj.map((enumValue: T) => ({
    value: enumValue,
    label: labelMap[enumValue],
  }))
}

export function equalIgnoreCase(a: string, b: string): boolean {
  return collator.compare(a, b) === 0
}

export function compareIgnoreCase(a: string, b: string): number {
  return collator.compare(a, b)
}

export function removeElementAtIndex<Type>(array: Type[], index: number): Array<Type> {
  const newArray = [...array]
  newArray.splice(index, 1)
  return newArray
}

export function toMap<T>(list: T[], serialize: (item: T) => string): Map<string, T> {
  const hashMap = new Map<string, T>()

  list.forEach((item: T) => {
    hashMap.set(serialize(item), item)
  })

  return hashMap
}

export function licenseNumberToString(formValue: number | undefined): string {
  if (formValue === undefined) {
    return "0"
  } else if (formValue === -1) {
    return appConstants.miscellaneous.LABEL_UNLIMITED
  } else {
    return String(formValue)
  }
}

export function licenseStringValueToNumber(value: string | undefined, defaultValue = 0): number {
  let result: number

  if (value === undefined || value === "") {
    result = defaultValue
  } else if (value === appConstants.miscellaneous.LABEL_UNLIMITED || value === "-1" || value === "i" || value === "I") {
    result = -1
  } else if (value === "-") {
    result = Number.NaN
  } else {
    const number = parseInt(value, 10)
    if (number < 0) {
      result = Number.NaN
    } else if (!isNumber(number)) {
      result = Number.NaN
    } else {
      result = number
    }
  }

  return result
}

export function getFullNameLabel(givenName: string | undefined, familyName: string | undefined): string {
  return `${familyName ?? ""} ${givenName ?? ""}`
}

export function identity<T>(value: T): T {
  return value
}
