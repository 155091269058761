export enum TtbStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  UNKNOWN = "UNKNOWN",
}

export function ttbStatusToLabel(status: TtbStatus | string): string {
  switch (status) {
    case TtbStatus.ACTIVE:
      return "A jour"
    case TtbStatus.DELETED:
      return "Supprimée"
    case TtbStatus.UNKNOWN:
      return "Inconnu"
    default:
      return ""
  }
}

export function ttbStatusToColor(status: TtbStatus | string): string {
  switch (status) {
    case TtbStatus.ACTIVE:
      return "#009069"
    case TtbStatus.DELETED:
      return "#F00C0C"
    case TtbStatus.UNKNOWN:
      return "#06B8F1"
    default:
      return ""
  }
}
