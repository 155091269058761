import React from "react"
import { Box } from "@mui/material"
import { DefaultTypography } from "../../../../../components/typography/default-typography"

type IProps = {
  label: string
  value: string
}

export function KPIFiche({ label, value }: Readonly<IProps>): React.JSX.Element {
  return (
    <Box sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 2, backgroundColor: "#F5F5F5" }}>
      <DefaultTypography label={label} fontSize="11px" lineHeight="26.5px" fontWeight={400} color="#8398A6" />
      <DefaultTypography label={value} fontSize="14px" lineHeight="24px" fontWeight={500} />
    </Box>
  )
}
