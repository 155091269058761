import { Box, createTheme, CssBaseline, ThemeProvider, useTheme } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BSSubmitDialog } from "../../../components/dialog/BSSubmitDialog"
import { SubsetInfo } from "../../../components/ifc-displayer/models/SubsetInfo"
import { pagesUrl } from "../../../core/appConstants"
import { BsModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSModelFileContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSModelImportContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelImportContext"
import { BSInputContext } from "../../../core/context/beem-shot/BSInputContext/BSInputContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { AppNavBarContext, NavbarTypeEnum } from "../../../core/context/nav-bar/AppNavBarContext"
import { CodeExtraitDisplay } from "../../../core/dto/code-extrait/CodeExtraitDisplay"
import { CodeExtrait } from "../../../core/dto/code-extrait/code-extrait"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"
import { codeStateEnum } from "../../../core/enum/codeStateEnum"
import { useBSParseBim } from "../../../core/hooks/beem-shot/useBSParseBim"
import { codeToKey } from "../../../core/services/code-service"
import { resolveUrl } from "../../../core/services/http-service"
import { BSBimModelAnalyzer } from "../components/BSBimModelAnalyzer"
import { BSCancelDialog } from "../components/Dialog/BSCancelDialog"

export function BSBimModelControl(): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId, bsVariantId } = useParams()

  const { file } = useContext(BSModelFileContext)
  const { selectedVariant, updateCalculStatus } = useContext(BSVariantContext)
  const { bsBimModel } = useContext(BsModelContext)
  const { bsProject } = useContext(BSProjectContext)
  const { refreshBSInput } = useContext(BSInputContext)
  const {
    codesExtraits,
    setCodesExtraits,
    viewer,
    setViewer,
    createCodeExtraits,
    isSubmittingExtractedCode,
    setTypesToElementIdsMap,
  } = useContext(BSModelImportContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)

  const [progress, setProgress] = useState<number>(0)
  const { analyzeModel } = useBSParseBim({
    version: bsProject?.codesVersion,
    setProgress,
    setTypesToElementIdsMap,
    setViewer,
    setCodesExtraits,
  })

  const [bimModelFile, setBimModelFile] = useState<File | undefined>(file)
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtrait | undefined>(undefined)
  const [isCancelProjectOpen, setIsCancelProjectOpen] = useState<boolean>(false)
  const [isloading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (file && bsBimModel?.id) {
      setBimModelFile(file)
    }
  }, [file, bsBimModel?.id])

  const theme = useTheme()
  const modifiedTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: "#ffffff",
        paper: "#ffffff",
      },
    },
  })

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId, selectedVariant?.id]))
    setStepHeader(1)
  }, [bsProjectId, selectedVariant?.id, setPreviousUrl, setStepHeader, setTypeNavBar])

  useEffect(() => {
    if (progress === 100) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [progress])

  function reset(): void {
    setBimModelFile(undefined)
    setCodesExtraits([])
  }

  function cancelUpload(): void {
    setBimModelFile(undefined)
    setCodesExtraits([])
    setProgress(0)
  }

  function cancelAction(): void {
    setIsCancelProjectOpen(true)
  }

  function handleClose(): void {
    setIsCancelProjectOpen(false)
  }

  function handlePauseProject(): void {
    setIsCancelProjectOpen(false)
    return navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
  }

  const handleClickAcv = useCallback(
    (codeExtrait: CodeExtraitDisplay, disableViewerHilight?: boolean) => {
      if (
        !disableViewerHilight &&
        `${codeExtrait.code + codeExtrait.occurence}` !==
          `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ""}`
      )
        viewer?.manager.subsetsManager.highlightCodeExtrait(codeExtrait)

      const newCode = codesExtraits.find((code) => codeToKey(code) === codeToKey(codeExtrait))
      setSelectedCodeExtrait(newCode)
    },
    [selectedCodeExtrait, viewer?.manager.subsetsManager, codesExtraits]
  )

  function handleClickCodeManquant(codeManquant: SubsetInfo): void {
    setSelectedCodeExtrait(undefined)
    viewer?.manager.subsetsManager.toggleSubsetHighlight(codeManquant)
  }

  function handleCodeVariantChange(codeVariant: codeStateEnum, unhighlight?: boolean): void {
    viewer?.manager.subsetsManager.updateCodeState(codeVariant, unhighlight ?? false)
    if (unhighlight) setSelectedCodeExtrait(undefined)
  }

  function handleClosePauseDialog(): void {
    setIsCancelProjectOpen(false)
  }

  return (
    <ThemeProvider theme={modifiedTheme}>
      <CssBaseline />
      <Box sx={{ backgroundColor: "#FFFFFF" }}>
        {bimModelFile !== undefined && (
          <BSBimModelAnalyzer
            isTree
            reset={reset}
            bimModelFile={bimModelFile}
            analyzeModel={analyzeModel}
            codesExtraits={codesExtraits}
            setProgress={setProgress}
            handleClickAcv={handleClickAcv}
            selectedCodeExtrait={selectedCodeExtrait}
            handleClickCodeManquant={handleClickCodeManquant}
            handleCodeVariantChange={handleCodeVariantChange}
            cancelUpload={cancelUpload}
            progress={progress}
          />
        )}

        <CustomBottomNavigation
          actionLabel="Charger la maquette"
          actionButton={() => {
            if (progress === 100 && !isSubmittingExtractedCode) {
              createCodeExtraits()
                .then(() => updateCalculStatus(CalculStatusEnum.WAITING_FOR_CONFIGURATION))
                .then(() => refreshBSInput())
                .then(() => {
                  navigate(resolveUrl(pagesUrl.BEEM_SHOT_CREATE_CALCUL, [bsProjectId, selectedVariant?.id, bsBimModel.id]))
                })
            }
          }}
          cancelLabel="Annuler"
          cancelAction={cancelAction}
          isSubmiting={isSubmittingExtractedCode || isloading}
        />

        <BSCancelDialog
          bsProjectId={bsProjectId}
          bsProjectName={bsProject?.name}
          bsVariantId={bsVariantId}
          handleClose={handleClosePauseDialog}
          isOpen={isCancelProjectOpen}
        />
        <BSSubmitDialog
          isOpen={isCancelProjectOpen}
          onClose={handleClose}
          onSubmit={handlePauseProject}
          titleText="Êtes-vous sûr ?"
          contentText={
            <>
              Vous souhaitez remettre à plus tard la création/mise à jour du projet "{bsProject?.name}" ? <br />
              Vous pouvez reprendre son édition à tout moment depuis la liste des projets Beem Shot.
            </>
          }
          cancelButtonLabel="Annuler"
          submitButtonLabel="Mettre en pause"
        />
      </Box>
    </ThemeProvider>
  )
}
