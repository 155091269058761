import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { pagesUrl } from "../../core/appConstants"
import { ProjectContext } from "../../core/context/project/project-context"
import { resolveUrl } from "../../core/services/http-service"

type IProps = {
  id?: string
  handleClose(): void
  open: boolean
}

export default function NavigationDialog({ id = "", handleClose, open }: IProps): React.JSX.Element {
  const navigate = useNavigate()
  const { project } = useContext(ProjectContext)
  const title = "Absence de paramètres dans la maquette numérique"

  function handleNavigate(): void {
    navigate(resolveUrl(pagesUrl.BEEM_SHOT_HELP_CENTER, []), {
      state: { projectId: project?.id },
    })
  }

  return (
    <Box>
      <Dialog maxWidth="md" open={open} onClose={handleClose} aria-describedby={id}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            Il semble que votre maquette numérique ne contienne pas les paramètres nécessaires de Time To Beem, ce qui
            empêche son utilisation pour l'analyse carbone.
          </Typography>
          <Typography paragraph>
            Vérifiez que votre maquette est conforme à notre guide méthodologique, accessible depuis notre page de tutoriels,
            où vous trouverez conventions et vidéos explicatives.
          </Typography>
          <Typography paragraph>
            Si le problème persiste, nous sommes là pour vous aider. Planifiez une session de support de 15 minutes via{" "}
            <a href="https://calendly.com/g-lescoulier/15min" target="_blank" rel="noopener noreferrer">
              ce lien
            </a>{" "}
            pour une assistance personnalisée.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Retour</Button>
          <Button variant="contained" onClick={handleNavigate}>
            Tutoriel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
