import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import { Filter, TableauDashboard } from "../../components/TableauDashboard/TableauDashboard"
import { FilterKey, tableauService, TableauViz } from "../../core/services/tableau/tableau-service"
import {
  ICADE_CLIENT_ID_PARAMETER_NAME,
  PROJECT_ID_PARAMETER_NAME,
  SECURE_TOKEN_PARAMETER_NAME,
} from "../../core/services/tableau/tableau-extensions-service"
import { useKairnial } from "./useKairnial"
import { KairnialDashboardInfos } from "../../core/dto/kairnial/KairnialDashboardInfos"
import { useQuery } from "../../core/hooks/miscellaneous/use-query"
import { KairnialRefreshNavigation } from "./KairnialRefreshNavigation"
import { getEnvProperty, PROPERTIES } from "../../core/services/environment-service"

export function KairnialDashboardPage(): React.JSX.Element {
  const { getDashboardInfos, fetchTableauJwt } = useKairnial()
  const environment: string = useMemo(() => getEnvProperty(PROPERTIES.REACT_APP_ENVIRONMENT), [])

  const query = useQuery()
  const clientId: string = useMemo(() => query.get("clientId") ?? "", [query])

  const secureToken: string = useMemo(() => query.get("secureToken") ?? "", [query])

  const [isReady, setIsReady] = useState<boolean>(false)
  const [jwt, setJwt] = useState<string | undefined>(undefined)
  const [dashboardInfos, setDashboardInfos] = useState<KairnialDashboardInfos | undefined>(undefined)

  const viz: React.MutableRefObject<any> = useRef<any>(new TableauViz())

  const tableauParameters: Filter[] = useMemo(() => {
    if (dashboardInfos) {
      return [
        {
          name: PROJECT_ID_PARAMETER_NAME,
          value: [environment !== "local" ? dashboardInfos.projectId : "66b63ba7382b8e57e3829d24"],
        },
        {
          name: SECURE_TOKEN_PARAMETER_NAME,
          value: [environment !== "local" ? secureToken : "WxCH09Wj-Z9ybMlhfLeQcAEE9G4"],
        },
        {
          name: ICADE_CLIENT_ID_PARAMETER_NAME,
          value: [environment !== "local" ? clientId : "dd980779-57e7-4114-b85a-bf3dd3387404"],
        },
      ]
    } else {
      return []
    }
  }, [clientId, dashboardInfos, environment, secureToken])

  const filterToAdd = useMemo(() => [], [])

  useEffect(() => {
    getDashboardInfos(secureToken, clientId).then((response: KairnialDashboardInfos) => {
      setDashboardInfos(response)
    })
  }, [getDashboardInfos, secureToken, clientId])

  useEffect(() => {
    fetchTableauJwt()
      .then((token) => {
        setJwt(token.jwt)
      })
      .catch(() => console.error("No JWT found or no calculation selected"))
  }, [fetchTableauJwt])

  const selectFirstItem = useCallback((): void => {
    console.info("selectFirstItem")
    if (dashboardInfos) {
      console.info("applyFilter", "Document_Filtre", FilterKey.DOCUMENT_NAME_FILTER, dashboardInfos.firstDocumentProjectName)
      tableauService
        .applyFilterToWorksheet(viz, "Document_Filtre", FilterKey.DOCUMENT_NAME_FILTER, [
          dashboardInfos.firstDocumentProjectName,
        ])
        .finally(() => {
          setIsReady(true)
        })
    }
  }, [dashboardInfos])

  if (dashboardInfos) {
    return (
      <>
        {!isReady && <CircularProgress />}
        <Box sx={{ display: "flex", justifyContent: "center", pb: "100px" }} visibility={isReady ? "visible" : "hidden"}>
          <TableauDashboard
            dashboardUrl={dashboardInfos.dashboardUrl}
            isDataReady
            tableauParameter={tableauParameters}
            vizRef={viz}
            filterToAdd={filterToAdd}
            currentJwt={jwt}
            hasJwt
            initDashboard={selectFirstItem}
          />
          <KairnialRefreshNavigation secureToken={secureToken} clientId={clientId} />
        </Box>
      </>
    )
  } else if (!isReady) {
    return <div>En cours de récupération des informations du tableau</div>
  } else {
    return <div>Impossible d'accéder aux informations du projet</div>
  }
}
