import { Box, Button, Grid, IconButton, Typography } from "@mui/material"

import CloseIcon from "@mui/icons-material/Close"
import React, { useContext, useEffect, useState } from "react"
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import city from "../../../../assets/img/city.png"
import { IconBox } from "../../../components/custom-icons/icon-box"
import { IconPencil } from "../../../components/custom-icons/icon-pencil"
import { pagesUrl } from "../../../core/appConstants"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { PageCenter } from "../../../core/enum/help-center/helpCenterEnum"
import { DownloadMethodAcceptDialog } from "./DownloadMethodAcceptDialog"

type IProps = {
  updateActualPage(toChange: PageCenter): void
  handleClose?(): void
}

export function HelperCenter({ updateActualPage, handleClose }: Readonly<IProps>): React.JSX.Element {
  const { setTitle, setPreviousUrl } = useContext(AppNavBarContext)

  useEffect(() => {
    setTitle("Tutoriel")
    setPreviousUrl(pagesUrl.HOME)
  }, [setTitle, setPreviousUrl])

  const [openModal, setOpenModal] = useState<boolean>(false)

  return (
    <>
      {handleClose && (
        <Box display="flex" justifyContent="space-between" pl={10} pr={10} pt={2}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <ArrowBackOutlinedIcon />
          </IconButton>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Grid container height="100%">
        <Grid item xs={2} />

        <Grid
          container
          item
          xs={8}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          gap={5}>
          <Typography variant="h5" fontWeight={600}>
            Laissez vous guider !
          </Typography>
          <Typography variant="body1" display="flex" justifyContent="center" textAlign="center" color="black">
            Vous avez besoin de paramétrer une maquette numérique ou apporter des informations à votre équipe ? <br />
            Vous êtes au bon endroit 😊
          </Typography>
          <Grid container display="flex" justifyContent="space-between" borderRadius={4} sx={{ background: "#C9FFE8" }}>
            <Grid item xs={8} display="flex" flexDirection="column" justifyContent="space-between" gap={3} sx={{ p: 5 }}>
              <Typography variant="h6">Appliquer la methode Time To Beem</Typography>
              <Typography variant="body1">
                Consultez la documentation Time To Beem pour renseigner correctement les codes ACV dans les objets modélisés
                de la maquette numérique.
              </Typography>

              <Grid container item xs={6}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: 3, color: "white", backgroundColor: "#009069" }}
                  onClick={() => setOpenModal(true)}>
                  Télécharger la méthode Time To Beem
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={4} p={3}>
              <img
                src={city}
                alt={city}
                height="200px"
                width="max-content"
                style={{
                  marginLeft: 15,
                  marginTop: -8,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={2}
                p={3}
                borderRadius={3}
                sx={{ background: "white" }}>
                <IconPencil />
                <Typography variant="h6">Télécharger les outils d'aide</Typography>
                <Typography variant="body1">
                  Paramétrez les logiciels REVIT et ARCHICAD et consultez les tutoriels vidéos.
                </Typography>

                <Grid container item xs={6}>
                  <Button
                    variant="contained"
                    onClick={() => updateActualPage(PageCenter.AIDE_PARAMETRAGE)}
                    sx={{ borderRadius: 3, color: "#009069", backgroundColor: "#C9FFE8" }}>
                    Parcourir
                  </Button>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={2}
                p={3}
                borderRadius={3}
                sx={{ background: "white" }}>
                <IconBox />
                <Typography variant="h6">Tester notre maquette d’exemple</Typography>
                <Typography variant="body1">
                  Consultez un exemple dans lequel notre équipe a paramétré une maquette numérique pour Time To Beem.
                </Typography>
                <Grid container item xs={6}>
                  <Button
                    variant="contained"
                    onClick={() => updateActualPage(PageCenter.TUTORIAL_EXEMPLE)}
                    sx={{ borderRadius: 3, color: "#009069", backgroundColor: "#C9FFE8" }}>
                    Parcourir
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <DownloadMethodAcceptDialog openModal={openModal} handleClose={() => setOpenModal(false)} />
        </Grid>

        <Grid item xs={2} />
      </Grid>
    </>
  )
}
