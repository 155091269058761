import { Chip } from "@mui/material"
import React, { useMemo } from "react"
import { customPalette } from "../../../../../../../theme"
import { Material } from "../../../../../../core/dto/material/material"
import { getDeclarationTypeBackground, getDeclarationTypeLabel } from "../../../../../../core/services/declaration-service"
import { IniesRecord } from "../../../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../../../core/dto/material/MaterialRecord"

type IProps = {
  selectedRow?: IniesRecord | MaterialRecord
  material?: Material
  isMaterial: boolean
}

export function TypologyBadge({ selectedRow, material, isMaterial }: IProps): React.JSX.Element {
  const typologieBackground = useMemo(
    () => getDeclarationTypeBackground(isMaterial ? material?.declarationType : selectedRow?.declarationType),
    [isMaterial, material?.declarationType, selectedRow?.declarationType]
  )

  function getDeclarationType(record: IniesRecord | MaterialRecord | Material | undefined): string {
    const declarationType = record ? record?.declarationType : ""
    return getDeclarationTypeLabel(declarationType)
  }

  return (
    <Chip
      sx={{ backgroundColor: typologieBackground, color: customPalette.default }}
      label={isMaterial ? getDeclarationType(material) : getDeclarationType(selectedRow)}
    />
  )
}
