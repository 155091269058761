import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined"
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../components/buttons/navigate-button/CustomBottomNavigation"
import { RseeProjectCardSeuil } from "../../../components/card/rsee/rsee-project-seuil-card"
import { IconCheckupList } from "../../../components/custom-icons/icon-checkup-list"
import { IconRseeDocumentCard } from "../../../components/custom-icons/icon-rsee-document-card"
import { IconTrashX } from "../../../components/custom-icons/icon-trash-x"
import { DeleteDialog } from "../../../components/dialog/delete-dialog"
import { SuccessContext } from "../../../components/layout/success-snackbar"
import { TableauDashboard } from "../../../components/TableauDashboard/TableauDashboard"
import { DefaultTypography } from "../../../components/typography/default-typography"
import { pagesUrl } from "../../../core/appConstants"
import { RseeDocumentContext } from "../../../core/context/beem-pilot/rsee/rsee-document-context"
import { RseeProjectContext } from "../../../core/context/beem-pilot/rsee/rsee-project-context"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { RseeSummaryTypeEnum } from "../../../core/enum/rsee/rseeSummaryTypeEnum"
import { useRsee } from "../../../core/hooks/rsee/use-rsee"
import { formatToFrench } from "../../../core/services/date-service"
import { getEnvProperty, PROPERTIES } from "../../../core/services/environment-service"
import { resolveUrl } from "../../../core/services/http-service"
import { TableauViz } from "../../../core/services/tableau/tableau-service"
import { RseeShareLinkDialog } from "../rseeShareLink/components/RseeShareLinkDialog"

export function RseeDocumentDashboard(): React.JSX.Element {
  const navigate = useNavigate()
  const { rseeProjectId, rseeDocumentId } = useParams()
  const location = useLocation()

  const { rseeProject } = useContext(RseeProjectContext)
  const { rseeDocument, dashboardUrl, isDataReady } = useContext(RseeDocumentContext)
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle, setPagination } =
    useContext(AppNavBarContext)
  const { organization } = useContext(OrganizationContext)

  const [openShareLink, setOpenShareLink] = useState(false)
  const [rseeDocumentIdForTableau, setRseeDocumentIdForTableau] = useState<string | undefined>(undefined)
  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] = useState<boolean>(false)

  const viz = useRef<any>(new TableauViz())
  const openSuccessSnackbar = useContext(SuccessContext)
  const { deleteRseeDocument } = useRsee()

  const [tabs, setTabs] = React.useState(0)

  useEffect(() => {
    const defaultTab = location.state?.defaultTab
    if (typeof defaultTab === "number") {
      setTabs(defaultTab)
    }
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_PILOT_PROJECT, [rseeDocument?.projectId]))
    setShowProjectStatus(true)
    setTitle(rseeProject?.projectName ?? "")
    setPagination(["Tableau de bord", "Beem Pilot", rseeProject?.projectName ?? ""])

    return () => {
      setShowProjectStatus(false)
      setTitle("BEEM PILOT")
    }
  }, [
    location,
    rseeDocument?.projectId,
    rseeProject?.projectName,
    setPagination,
    setPreviousUrl,
    setShowProjectStatus,
    setStepHeader,
    setTitle,
    setTypeNavBar,
  ])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide])

  useEffect(() => {
    if (getEnvProperty(PROPERTIES.REACT_APP_ENVIRONMENT) === "local") {
      setRseeDocumentIdForTableau("65ae97c77fff780724c27188") // Operation_test from QA
    } else {
      setRseeDocumentIdForTableau(rseeDocument?.id)
    }
  }, [rseeDocument?.id])

  function handleClose(): void {
    setOpenShareLink(false)
    setOpenDeleteDocumentDialog(false)
  }

  function deleteDocument(documentId: string): void {
    deleteRseeDocument(documentId).then(() => {
      navigate(
        resolveUrl(pagesUrl.BEEM_PILOT_PROJECT, [rseeDocument?.projectId], {
          from: RseeSummaryTypeEnum.RSEE_DASHBOARD,
        })
      )
      openSuccessSnackbar("Suppression du document RSEE réalisé avec succès")
    })
  }

  return (
    <Box>
      {rseeProject && rseeProject.projectStatus !== ProjectStatusEnum.IN_PROGRESS && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            color: "white",
            borderRadius: 4,
            backgroundColor: "#070F27",
          }}>
          Mode lecture
        </Box>
      )}
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" px={3} py={2}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          columnGap={0.5}
          justifyItems="center">
          <DefaultTypography label="Crée par " color="#070F27" fontWeight={400} fontSize="11px" lineHeight="16.5px" />
          <DefaultTypography
            label={rseeProject?.createdByUser ?? ""}
            color="#070F27"
            fontWeight={500}
            fontSize="11px"
            lineHeight="16.5px"
          />
          <Typography variant="subtitle1" fontSize={11}>
            {`· ${organization?.businessName ?? ""} · `}
          </Typography>

          <Typography variant="subtitle1" fontSize={11} fontWeight={400} sx={{ color: "#8F8F8F" }}>
            {rseeDocument && ` Mise à jour le ${formatToFrench(new Date(rseeDocument.lastModifiedDate))}`}
          </Typography>
        </Box>

        <Box>{rseeDocument && <RseeProjectCardSeuil isConforme={rseeDocument?.compliant ?? false} />}</Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        mx={3}
        gap={2}
        sx={{ background: "white", borderRadius: 3, boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)" }}>
        <Box p={3}>
          <IconRseeDocumentCard height={70} width={70} />
        </Box>

        <Box display="flex" flexDirection="column" sx={{ border: 2, borderColor: "#ECF3F3", borderRadius: 2, p: 1 }}>
          <Typography variant="body2" fontSize="12px" color="#8398A6">
            Date de l'étude de thermique
          </Typography>
          <Typography variant="body2">{rseeDocument?.labelDateEtude}</Typography>
        </Box>

        {false && (
          <Box display="flex" flexDirection="column" sx={{ border: 2, borderColor: "#ECF3F3", borderRadius: 2, p: 1 }}>
            <Typography variant="body2" fontSize="12px" color="#8398A6">
              Date de l'étude environnementale
            </Typography>
            <Typography variant="body2">Date à changer</Typography>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          columnGap={0.5}
          justifyItems="center"
          sx={{ marginLeft: "auto", pr: 2 }}>
          {rseeProject?.hasRight && (
            <Tooltip title="Partager ce fichier RSEE" placement="bottom" arrow>
              <IconButton onClick={() => setOpenShareLink(true)} sx={{ background: "#C9FFE8", borderRadius: 3, mr: 1 }}>
                <ShareOutlinedIcon fontSize="medium" color="success" sx={{ pr: 1 }} />
                <Typography variant="body2" sx={{ color: "#009069" }}>
                  Partager
                </Typography>
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Consulter le diagnostic de l'import" placement="bottom" arrow>
            <IconButton
              onClick={() =>
                navigate(
                  resolveUrl(pagesUrl.BEEM_PILOT_DOCUMENT_SUMMARY_IMPORT, [rseeDocument?.projectId, rseeDocument?.id], {
                    from: RseeSummaryTypeEnum.RSEE_DASHBOARD,
                  })
                )
              }
              sx={{ background: "#ECF3F3", borderRadius: 3, mr: 1 }}>
              <IconCheckupList />
            </IconButton>
          </Tooltip>

          {rseeProject?.projectStatus === ProjectStatusEnum.IN_PROGRESS && (
            <Tooltip title="Supprimer le document" placement="bottom" arrow>
              <IconButton onClick={() => setOpenDeleteDocumentDialog(true)} sx={{ background: "#ECF3F3", borderRadius: 2 }}>
                <IconTrashX />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={9} />
        {rseeDocumentIdForTableau && dashboardUrl !== "" && (
          <Grid item xs={12}>
            <TableauDashboard
              dashboardUrl={dashboardUrl}
              isDataReady={isDataReady}
              tableauParameter={[{ name: "0P_Document_Id", value: [rseeDocumentIdForTableau] }]}
              filterToAdd={[]}
              vizRef={viz}
            />
          </Grid>
        )}
      </Grid>

      <RseeShareLinkDialog
        open={openShareLink}
        handleClose={handleClose}
        isWritingMode={rseeProject?.projectStatus === ProjectStatusEnum.IN_PROGRESS}
      />

      <DeleteDialog
        id="deleteRseeDocument"
        title="Suppression du document RSEE"
        contentText="Êtes vous sûr de vouloir supprimer ce document du projet RSEE ?"
        handleClose={handleClose}
        action={() => {
          if (rseeDocument?.id) {
            deleteDocument(rseeDocument?.id)
          }
        }}
        open={openDeleteDocumentDialog}
      />

      {rseeProject?.projectStatus === ProjectStatusEnum.IN_PROGRESS && (
        <CustomBottomNavigation
          actionLabel="Mettre à jour le fichier"
          actionButton={() => navigate(resolveUrl(pagesUrl.BEEM_PILOT_UPLOAD_PAGE, [rseeProjectId, rseeDocumentId]))}
        />
      )}
    </Box>
  )
}
