import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { Box, CircularProgress, Fade, Grid, IconButton, Tooltip, Typography, Zoom } from "@mui/material"
import { useContext, useState } from "react"
import { CustomBottomNavigation } from "../../../../components/buttons/navigate-button/CustomBottomNavigation"
import { CustomPagination } from "../../../../components/buttons/pagination/CustomPagination"
import { BSVariantCard } from "../../../../components/card/beem-shot/BSVariantCard"
import { DefaultTypography } from "../../../../components/typography/default-typography"
import { BSProjectContext } from "../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { OrganizationContext } from "../../../../core/context/organization/organization-context"
import { BSVariant } from "../../../../core/dto/beem-shot/BSVariant/BSVariant"
import { ProjectStatusEnum } from "../../../../core/enum/projectStatusEnum"
import { formatToFrench } from "../../../../core/services/date-service"
import { BSProjectForm } from "../../components/BSProjectForm"
import { BSVariantForm } from "../../components/BSVariant/BSVariantForm"
import { DeleteBSProjectDialog } from "../../components/Dialog/DeleteBSProjectDialog"
import { UpdateBSProjectDialog } from "../../components/Dialog/UpdateBSProjectDialog"

const ITEMS_PER_PAGE = 6

export function BSVariantList(): React.JSX.Element {
  const { bsVariants, selectedVariant } = useContext(BSVariantContext)
  const { bsProject } = useContext(BSProjectContext)
  const { organization } = useContext(OrganizationContext)

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openUpdateProjectInfo, setOpenUpdateProjectInfo] = useState<boolean>(false)
  const [openManageProjectStatus, setOpenManageProjectStatus] = useState<boolean>(false)
  const [openCreateVariantForm, setOpenCreateVariantForm] = useState<boolean>(false)

  const [page, setPage] = useState(1)

  const isLoading = false

  function handleClose(): void {
    setOpenDeleteDialog(false)
    setOpenUpdateProjectInfo(false)
  }

  function handleCloseVariant(): void {
    setOpenCreateVariantForm(false)
  }

  function handleChangePage(event: any, value: React.SetStateAction<number>): void {
    setPage(value)
  }

  return (
    <>
      {!bsProject ? (
        <Box height="60vh" width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Fade in timeout={600}>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                alignContent="center"
                columnGap={0.5}
                justifyItems="center">
                <DefaultTypography label="Crée par " color="#070F27" fontWeight={400} fontSize="11px" lineHeight="16.5px" />
                <DefaultTypography
                  label={bsProject?.createdByUser ?? ""}
                  color="#070F27"
                  fontWeight={500}
                  fontSize="11px"
                  lineHeight="16.5px"
                />
                <Typography variant="subtitle1" fontSize={11}>
                  {`· ${organization?.businessName} · `}
                </Typography>

                <Typography variant="subtitle1" fontSize={11} fontWeight={400} sx={{ color: "#8F8F8F" }}>
                  {` Mise à jour le ${formatToFrench(bsProject?.lastModifiedDate)}`}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                alignContent="center"
                columnGap={0.5}
                justifyItems="center"
                p={1}>
                {bsProject?.status === ProjectStatusEnum.IN_PROGRESS && (
                  <Tooltip title="Modifier les informations du projet" placement="bottom" arrow>
                    <IconButton
                      onClick={() => setOpenUpdateProjectInfo(true)}
                      sx={{ background: "#F5F8F8", borderRadius: 2, mr: 1, mt: 1 }}>
                      <CreateOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="Supprimer le projet" placement="bottom" arrow>
                  <IconButton
                    onClick={() => setOpenDeleteDialog(true)}
                    sx={{ background: "#F5F8F8", borderRadius: 2, mr: 1, mt: 1 }}>
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Gérer l'état du projet" placement="bottom" arrow>
                  <IconButton
                    onClick={() => setOpenManageProjectStatus(true)}
                    sx={{ background: "#F5F8F8", borderRadius: 2, mr: 1, mt: 1 }}>
                    <SettingsOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            <Box>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Grid container columnSpacing={4} alignItems="flex-start" height="100%" rowGap={3}>
                  {bsVariants.length > 0 ? (
                    bsVariants.map((bsVariant: BSVariant, index) => (
                      <Zoom in style={{ transitionDelay: `${index * 150}ms` }} key={bsVariant.id}>
                        <Grid item xs={12} md={4} lg={4} key={bsVariant.id}>
                          <BSVariantCard key={bsVariant.id} bsVariant={bsVariant} />
                        </Grid>
                      </Zoom>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      width="100%">
                      <Typography fontSize="24px" fontWeight={600} lineHeight="36px" textAlign="center" color="#374771">
                        Aucun résultat
                      </Typography>
                      <Typography variant="body1">Modifier les options de recherche.</Typography>
                    </Box>
                  )}
                </Grid>
              )}
            </Box>
          </Box>
        </Fade>
      )}

      {bsProject && !isLoading && (
        <CustomPagination
          count={Math.ceil(bsVariants.length / ITEMS_PER_PAGE)}
          page={page}
          handlePagination={handleChangePage}
        />
      )}

      {bsProject?.status === ProjectStatusEnum.IN_PROGRESS && (
        <CustomBottomNavigation
          actionLabel="créer une nouvelle variante"
          actionButton={() => setOpenCreateVariantForm(true)}
        />
      )}

      <UpdateBSProjectDialog open={openManageProjectStatus} setOpen={setOpenManageProjectStatus} />

      <DeleteBSProjectDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} />

      <BSProjectForm open={openUpdateProjectInfo} handleClose={handleClose} />

      <BSVariantForm open={openCreateVariantForm} handleClose={handleCloseVariant} bsVariant={selectedVariant} />
    </>
  )
}
