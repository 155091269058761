import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSItem } from "../../dto/beem-shot/BSItem/BSItem"
import { BSItemCategory } from "../../dto/beem-shot/BSItem/BSItemCategory"
import { BSItemEnum } from "../../dto/beem-shot/BSItem/BSItemEnum"
import { BSItemSubCategory } from "../../dto/beem-shot/BSItem/BSItemSubCategory"
import { BSItemUpdateDto } from "../../dto/beem-shot/BSItem/BSItemUpdateDto"
import { BSMacroComponent } from "../../dto/beem-shot/BSMacroComponent/BSMacroComponent"
import { BSMacroComponentType } from "../../dto/beem-shot/BSMacroComponent/BSMacroComponentType"
import { resolveUrl } from "../../services/http-service"
import { useHttp } from "../use-http"

type BSItemHook = {
  getBSItems(bsVariantId: string): Promise<BSItem[]>
  getBSItemsForSubCategory(bsVariantId: string, subCategory: BSItemSubCategory): Promise<BSItem[]>
  getBSCategories(): Promise<BSItemCategory[]>
  getBSMacroComponentTypeList(bsItemEnum: BSItemEnum): Promise<BSMacroComponentType[]>
  getMacroListOptions(
    bsVariantId: string,
    bsItemId: string,
    bsMacroComponentType: BSMacroComponentType
  ): Promise<BSMacroComponent[]>
  updateBSItem(toUpdate: BSItemUpdateDto): Promise<BSItem>
}

export function useBSItem(): BSItemHook {
  const { get, put } = useHttp()

  return useMemo(
    () => ({
      getBSItems(bsVariantId: string): Promise<BSItem[]> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_ITEM, [bsVariantId])).then((response) => response.json())
      },
      getBSItemsForSubCategory(bsVariantId: string, subCategory: BSItemSubCategory): Promise<BSItem[]> {
        return get(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_ITEM, [bsVariantId], { subCategory: subCategory.name })
        ).then((response) => response.json())
      },
      getBSCategories(): Promise<BSItemCategory[]> {
        return get(appConstants.apiEndpoints.BEEM_SHOT_CATEGORIES).then((response) => response.json())
      },
      getBSMacroComponentTypeList(bsItemEnum: BSItemEnum): Promise<BSMacroComponentType[]> {
        return get(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_MACRO_COMPONENT_TYPE, [], {
            bsItemEnum: bsItemEnum.name,
          })
        ).then((response) => response.json())
      },
      getMacroListOptions(
        bsVariantId: string,
        bsItemId: string,
        bsMacroComponentType: BSMacroComponentType
      ): Promise<BSMacroComponent[]> {
        return get(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_MACRO_COMPONENT_OPTIONS, [bsVariantId, bsItemId], {
            bsMacroComponentType: bsMacroComponentType.name,
          })
        ).then((response) => response.json())
      },
      updateBSItem(toUpdate: BSItemUpdateDto): Promise<BSItem> {
        return put(appConstants.apiEndpoints.BEEM_SHOT_ITEM_UPDATE, toUpdate).then((response) => response.json())
      },
    }),
    [get, put]
  )
}
