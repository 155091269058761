import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { DepartmentContext } from "../../../../core/context/departmentContext"
import { ProjectContext, ProjectStore } from "../../../../core/context/project/project-context"
import { altitudeEnum, altitudeEnumLabels } from "../../../../core/enum/altitudeEnum"
import { CalculationTypesEnum } from "../../../../core/enum/calculationTypeEnum"
import { usagesLabels } from "../../../../core/enum/usageEnum"
import { CalculationForm } from "../../../../core/hooks/calcul-carbon/use-calcul-carbon-information-project-mutation"
import { useCalculation } from "../../../../core/hooks/use-calculation"
import { enumToSelectOptions } from "../../../../core/services/helper-service"
import SelectVariant from "../components/select-variant"
import { SelectInput } from "../../../../components/inputs/select-input/SelectInput"

const calculationTypeData = Object.keys(CalculationTypesEnum).map((i) => i as keyof typeof CalculationTypesEnum)
type Props = {
  selectedVariant: 1 | 2
  handleChangeVariant: (v: 1 | 2) => void
  handleChangeForm: (field: string, value: string | number) => void
  formValueVariant: CalculationForm
  errors: Record<string, string>
  disabled: boolean
}
export default function GeneralInformationForm({
  selectedVariant,
  handleChangeVariant,
  handleChangeForm,
  formValueVariant,
  errors,
  disabled,
}: Readonly<Props>): React.JSX.Element {
  const [climaticZones, setClimaticZones] = useState<string[]>([])
  const { fetchClimaticZones } = useCalculation()

  const { project } = useContext<ProjectStore>(ProjectContext)
  const { deparmentsList } = useContext(DepartmentContext)

  useEffect(() => {
    fetchClimaticZones().then((response: string[]) => {
      setClimaticZones(response)
    })
  }, [fetchClimaticZones])

  return (
    <Box
      px={{
        xs: 0,
        sm: 4,
        lg: "388px",
      }}
      mt={2}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel sx={{ textTransform: "uppercase", backgroundColor: "white" }}>USAGE PRINCIPAL DE LA ZONE</InputLabel>
            <Select
              fullWidth
              onChange={(e) => handleChangeForm("usage", e?.target?.value)}
              value={formValueVariant.usage?.toString() || ""}
              error={!!errors?.usage}
              aria-describedby="usage"
              disabled={disabled}>
              {usagesLabels.map((lv) => (
                <MenuItem key={`${lv.value}`} value={lv.value.toString()}>
                  {lv.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error id="usage">
              {errors?.usage ? `${errors?.usage}` : ""}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Tooltip
            title="La zone climatique est définie par le département sélectionné lors de la création du projet"
            placement="right"
            arrow>
            <Grid item xs={12}>
              <TextField
                id="climaticZone"
                label="Zone climatique"
                fullWidth
                value={deparmentsList.find((x) => x.departmentId === project?.departmentId)?.climaticZone || ""}
                disabled
              />
            </Grid>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <SelectInput
            id="altitude"
            label="Altitude"
            selectedOption={formValueVariant.altitude}
            options={enumToSelectOptions(altitudeEnum, altitudeEnumLabels)}
            mode="event"
            handleEventChange={(e) => handleChangeForm("altitude", e?.target?.value)}
            isBeemShot
            errors={errors}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel sx={{ textTransform: "uppercase", backgroundColor: "white" }}>Type de calcul</InputLabel>
            <Select
              fullWidth
              onChange={(e) => handleChangeForm("calculationType", e?.target?.value)}
              value={formValueVariant.calculationType || ""}
              error={!!errors?.calculationType}
              aria-describedby="calculationType"
              disabled={disabled}>
              {calculationTypeData.map((key) => (
                <MenuItem key={`${key}`} value={key} disabled={key.toString() !== CalculationTypesEnum.RE2020.toString()}>
                  {CalculationTypesEnum[key]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error id="calculationType">
              {errors?.calculationType ? `${errors?.calculationType}` : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={3} display="flex" flexDirection="row" justifyContent="center">
          <SelectVariant selectedVariant={selectedVariant} handleChangeVariant={handleChangeVariant} />
        </Grid>
        {selectedVariant === 1 && (
          <Grid item xs={12} px={2}>
            <TextField
              fullWidth
              label="NOM DE LA BASE"
              onChange={(e) => handleChangeForm("variantName1", e.target.value)}
              variant="outlined"
              value={formValueVariant.variantName1 || ""}
              error={!!errors?.variantName1}
              helperText={errors?.variantName1 && errors.variantName1}
              disabled={disabled}
            />
          </Grid>
        )}
        {selectedVariant === 2 && (
          <Grid item xs={12} px={2}>
            <TextField
              fullWidth
              label="NOM DE LA VARIANTE"
              onChange={(e) => handleChangeForm("variantName2", e.target.value)}
              variant="outlined"
              value={formValueVariant.variantName2 || ""}
              error={!!errors?.variantName2}
              helperText={errors?.variantName2 && errors?.variantName2}
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
