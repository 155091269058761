import { debounce } from "@mui/material"
import { useCallback, useMemo, useState } from "react"

interface FormHook {
  limit: number
  lastRecordId: string | undefined
  setLastRecordId: React.Dispatch<React.SetStateAction<string | undefined>>
  searchState: string | undefined
  setSearchState: React.Dispatch<React.SetStateAction<string | undefined>>
  modalPageMaterial: number
  setModalPageMaterial: React.Dispatch<React.SetStateAction<number>>

  getRowPerPage(): number

  setRowsPerPage(newLimit: number): void

  handleSearchDelayed(e: string): void

  handleSearchModalPaginated(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void
}

interface IProps {
  fetchNextPageData(
    limit: number,
    lastRecordId?: string | undefined,
    searchState?: string | undefined
  ): Promise<string | undefined>
}

export function usePagination({ fetchNextPageData }: IProps): FormHook {
  const [lastRecordId, setLastRecordId] = useState<string | undefined>(undefined)
  const [lastPrevModalMaterial, setLastPrevModalMaterial] = useState<string[]>([])
  const [searchState, setSearchState] = useState<string | undefined>(undefined)
  const [modalPageMaterial, setModalPageMaterial] = useState(0)
  const [limit, setLimit] = useState(5)

  const getRowPerPage = useCallback((): number => limit, [limit])

  const setRowsPerPage = useCallback(
    (newLimit: number): void => {
      setLimit(newLimit)
    },
    [setLimit]
  )

  const handleSearchDelayed = useMemo(
    () =>
      debounce((e: string) => {
        setModalPageMaterial(0)
        fetchNextPageData(limit, undefined, e).then((lastId) => setLastRecordId(lastId))
      }, 1000),
    [fetchNextPageData, limit]
  )

  const handleSearchModalPaginated = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      if (newPage > modalPageMaterial) {
        fetchNextPageData(limit, lastRecordId, searchState).then((lastId) => {
          if (lastRecordId) {
            setLastPrevModalMaterial([...lastPrevModalMaterial, lastRecordId])
          }
          setLastRecordId(lastId)
        })
      } else if (newPage <= modalPageMaterial) {
        lastPrevModalMaterial.pop()
        const newLastPrevInie = [...lastPrevModalMaterial]
        const previousLastId = newPage === 0 ? undefined : newLastPrevInie[newLastPrevInie.length - 1]
        fetchNextPageData(limit, previousLastId, searchState).then((lastId) => {
          setLastRecordId(lastId)
        })
      }

      setModalPageMaterial(newPage)
    },
    [fetchNextPageData, lastPrevModalMaterial, lastRecordId, limit, modalPageMaterial, searchState]
  )

  return useMemo(
    () => ({
      limit,
      lastRecordId,
      setLastRecordId,
      searchState,
      setSearchState,
      modalPageMaterial,
      setModalPageMaterial,
      getRowPerPage,
      setRowsPerPage,
      handleSearchDelayed,
      handleSearchModalPaginated,
    }),
    [
      limit,
      lastRecordId,
      searchState,
      modalPageMaterial,
      getRowPerPage,
      setRowsPerPage,
      handleSearchDelayed,
      handleSearchModalPaginated,
    ]
  )
}
