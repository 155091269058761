import { Box, Typography } from "@mui/material"
import React, { RefObject, useEffect, useRef, useState } from "react"
import ChefCookingDashboards from "../../../assets/img/chef-cooking-dashboards.jpeg"
import { useTableau } from "../../core/hooks/use-tableau"
import { TableauEventType } from "../../core/services/tableau/tableau-service"

export type Filter = {
  name: string
  value: string[]
}

type IProps = {
  dashboardUrl: string
  isDataReady: boolean
  tableauParameter: Filter[]
  filterToAdd: Filter[]
  vizRef: React.MutableRefObject<any>
  currentJwt?: string | undefined
  hasJwt?: boolean
  initDashboard?(): void
}

export function TableauDashboard({
  dashboardUrl,
  isDataReady,
  tableauParameter,
  filterToAdd,
  vizRef,
  currentJwt,
  hasJwt = false,
  initDashboard,
}: Readonly<IProps>): React.JSX.Element {
  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
  const { fetchTableauJwt } = useTableau()
  const [jwt, setJwt] = useState<string | undefined>(currentJwt)

  useEffect(() => {
    if (hasJwt) {
      setJwt(currentJwt)
    }
  }, [currentJwt, hasJwt])

  useEffect(() => {
    if (isDataReady && !hasJwt) {
      fetchTableauJwt()
        .then((token) => {
          setJwt(token.jwt)
        })
        .catch(() => console.error("No JWT found or no calculation selected"))
    }
  }, [fetchTableauJwt, hasJwt, isDataReady])

  useEffect(
    () => {
      if (jwt && ref && ref.current && isDataReady) {
        console.info("dashboardUrl", dashboardUrl)
        console.info("parameter", tableauParameter)
        console.info("filterToAdd", filterToAdd)
        // Tableau viz doc https://github.com/tableau/embedding-api-v3-guide/blob/main/README.md
        vizRef.current.token = jwt
        vizRef.current.src = dashboardUrl

        tableauParameter.forEach((filter) => {
          const vizParameter = document.createElement("viz-parameter")
          vizParameter.setAttribute("name", filter.name)
          filter.value.forEach((value) => {
            vizParameter.setAttribute("value", value)
          })
          vizRef.current.appendChild(vizParameter)
        })

        filterToAdd.forEach((filter) => {
          filter.value.forEach((value) => {
            vizRef.current.addFilter(filter.name, value)
          })
        })

        vizRef.current.toolbar = "hidden"

        vizRef.current.addEventListener(TableauEventType.FirstInteractive, () => {
          console.info("FirstInteractive")
          if (initDashboard) {
            initDashboard()
          }
        })

        ref.current.appendChild(vizRef.current)
      }
    },
    // vizRef
    [jwt, isDataReady, dashboardUrl, tableauParameter, filterToAdd, initDashboard]
  )

  return isDataReady ? (
    <Box display="flex" justifyContent="center">
      <div id="tableau-dashboard" ref={ref} />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "60vh", // Set the height to 80% of the viewport height
      }}>
      <Typography variant="h6" align="center">
        Votre Tableau de bord est en cours de préparation par nos meilleurs chefs.
      </Typography>
      <Typography variant="h6" align="center">
        Il sera prêt dans moins de 2min.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxHeight: "100%",
        }}>
        <img
          src={ChefCookingDashboards}
          alt="A chef is making the dashboards"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Box>
    </Box>
  )
}
